import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import { useGetMyNotificationPreferences, useUpdateMyNotificationPreferences } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ActionButtons } from 'components/Shared/ActionButtons';
import { HorizontalLine } from 'components/Shared/HorizontalLine';
import { NumberInput } from 'components/Shared/NumberInput';
import { PreventTransition } from 'components/Shared/PreventTransition';
import { ProgressButton } from 'components/Shared/ProgressButton';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  NotificationType,
  UpdateMyNotificationPreferencesMutationVariables,
  UserNotificationPreferenceInputType,
} from 'tillr-graphql';

type MyPref = {
  isEnabled: boolean;
  days?: number;
};

export function NotificationPreferences() {
  const { loading, error, data } = useGetMyNotificationPreferences();

  const { search } = useLocation();
  const focus = search ? new URLSearchParams(search).get('focus') : undefined;

  const [updateMyNotificationPreferences, mutationState] = useUpdateMyNotificationPreferences();

  const [myPrefs, setMyPrefs] = useState<Record<string, MyPref>>();
  const [changesPending, setChangesPending] = useState(false);

  useEffect(() => {
    if (data?.notificationTypes) {
      setMyPrefs(
        Object.fromEntries(
          data.notificationTypes.map((type) => {
            const myPref = data?.myNotificationPreferences?.find(
              (x) => x.notificationType === type.value,
            );
            const isEnabled = myPref?.isEnabled ?? type.defaultValue;
            const days =
              type.value === NotificationType.TaskReminder
                ? myPref?.customData
                  ? Number(myPref.customData) / 24
                  : 1
                : undefined;

            return [type.value, { isEnabled, days }];
          }),
        ),
      );
    }
  }, [data]);

  const handleToggleEnabled = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const type = event.target.name;

    setMyPrefs((prevPrefs) => ({
      ...prevPrefs,
      [type]: { ...prevPrefs![type], isEnabled: checked },
    }));

    setChangesPending(true);
  };

  const handleChangeTaskReminderDays = (value: number) => {
    setMyPrefs((prevPrefs) => ({
      ...prevPrefs,
      [NotificationType.TaskReminder]: {
        ...prevPrefs![NotificationType.TaskReminder],
        days: value,
      },
    }));

    setChangesPending(true);
  };

  const handleSubmit = () => {
    const preferences: Array<UserNotificationPreferenceInputType> = Object.entries(myPrefs!).map(
      ([type, myPref]) => ({
        notificationType: type as NotificationType,
        isEnabled: myPref.isEnabled,
        customData:
          type === NotificationType.TaskReminder ? ((myPref.days ?? 0) * 24).toString() : null,
      }),
    );
    const variables: UpdateMyNotificationPreferencesMutationVariables = { preferences };
    updateMyNotificationPreferences({ variables }).then(() => {
      setChangesPending(false);
    });
  };

  return (
    <div>
      <PreventTransition active={changesPending} />

      <Typography variant="h2" noWrap>
        Notification preferences
      </Typography>
      <HorizontalLine margin={[2, 0, 2, 0]} />

      {loading && <ActivityIndicator />}
      {error && <ErrorDisplay error={error} />}
      {data?.notificationTypes && myPrefs && (
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography>Email me when...</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.notificationTypes.map((type) => {
                      const myPref = myPrefs[type.value];

                      return (
                        <TableRow
                          key={type.value}
                          style={{ backgroundColor: focus === type.value ? '#fff4e5' : '' }}
                        >
                          <TableCell>
                            {type.description}
                            {type.value === NotificationType.TaskReminder && (
                              <NumberInput
                                item="day"
                                value={myPref.days ?? 1}
                                onChange={handleChangeTaskReminderDays}
                                disabled={!myPref.isEnabled}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Switch
                                name={type.value}
                                checked={myPref.isEnabled}
                                onChange={handleToggleEnabled}
                                color="primary"
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              {mutationState.error && <ErrorDisplay error={mutationState.error} />}
              {mutationState.data && !changesPending && (
                <Alert severity="success">Preferences updated successfully!</Alert>
              )}
              <ActionButtons>
                <ProgressButton
                  onClick={handleSubmit}
                  label="Update"
                  loading={mutationState.loading}
                  disabled={!changesPending}
                />
              </ActionButtons>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
