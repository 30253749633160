import { Box } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import React, { useContext } from 'react';
import { TaskListItemFragment } from 'tillr-graphql';
import { UserProfileContext } from 'UserProfileContext';
import { getTaskStatusFeatures } from '../utils';
import { CloseTaskCheckBox } from './CloseTaskCheckBox';

const useStyles = makeStyles(() =>
  createStyles({
    statusChip: {
      height: 'auto',
      color: '#ffffff',
      '& .MuiChip-icon': {
        color: '#ffffff',
      },
      '& .MuiChip-label': {
        display: 'block',
        whiteSpace: 'pre-wrap',
      },
    },
  }),
);

interface IProps {
  task: TaskListItemFragment;
}

export function TaskStatus(props: IProps) {
  const { task } = props;

  const classes = useStyles();
  const userProfile = useContext(UserProfileContext);

  const { label, color } = getTaskStatusFeatures(task);

  // May have to change if we start using Teams again
  const canCloseTask =
    task.open && userProfile?.canEditTask(task.createdByUser.id, task.assignedToUsers);

  return (
    <Box display="flex">
      {canCloseTask ? (
        <CloseTaskCheckBox taskId={task.id} />
      ) : (
        <IconButton disabled style={{ marginLeft: -12 }}>
          {task.open ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
        </IconButton>
      )}
      <Chip label={label} className={classes.statusChip} style={{ backgroundColor: color }} />
    </Box>
  );
}
