/****************************************************************************
 * THIS FILE IS GENERATED BY RUNNING `npm run gql`, DO NOT EDIT DIRECTLY :) *
 ****************************************************************************/
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Guid: any;
  DateTimeOffset: any;
  DateTime: any;
};

export enum AssigneeType {
  User = 'USER',
  Team = 'TEAM'
}

export type AttachmentType = {
  __typename?: 'AttachmentType';
  id: Scalars['Guid'];
  blobStorageId: Scalars['Guid'];
  fileName: Scalars['String'];
  name: Scalars['String'];
  createdDateTime: Scalars['DateTimeOffset'];
  createdByUser: UserType;
  parentId?: Maybe<Scalars['Guid']>;
  parentType?: Maybe<ParentType>;
};

export type CreateAttachmentsResponseType = {
  __typename?: 'CreateAttachmentsResponseType';
  attachments: Array<AttachmentType>;
  uploadUrlTemplate: Scalars['String'];
};

export type CreateDataFileType = {
  __typename?: 'CreateDataFileType';
  dataFileId: Scalars['Int'];
  uploadUrl: Scalars['String'];
};

export type CreateFormInputType = {
  id?: Maybe<Scalars['Guid']>;
  templateId: Scalars['Guid'];
  name: Scalars['String'];
  formDataJson: Scalars['String'];
  isSubmitted: Scalars['Boolean'];
};

export type CreateInvitationInputType = {
  email: Scalars['String'];
  roleId: Scalars['Guid'];
  modules: Array<Module>;
  siteIds: Array<Scalars['Int']>;
  customData?: Maybe<Scalars['String']>;
};

export type CreateLibraryFilesResponseType = {
  __typename?: 'CreateLibraryFilesResponseType';
  files: Array<LibraryFileType>;
  uploadUrlTemplate: Scalars['String'];
};

export type CreateReportInputType = {
  name: Scalars['String'];
  isPublished: Scalars['Boolean'];
  reportingDatabase: ReportingDatabase;
  sqlQuery: Scalars['String'];
  uiData?: Maybe<Scalars['String']>;
};

export type CreateRoleInputType = {
  name: Scalars['String'];
  permissions: Array<Scalars['String']>;
};

export type CreateSiteInputType = {
  name: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CreateTagInputType = {
  parentType: ParentType;
  parentId: Scalars['Guid'];
  value: Scalars['String'];
};

export type CreateTaskInputType = {
  id?: Maybe<Scalars['Guid']>;
  name: Scalars['String'];
  description: Scalars['String'];
  parentId?: Maybe<Scalars['Guid']>;
  dueDate: Scalars['DateTimeOffset'];
  assignees: Array<TaskAssigneeInputType>;
};

export enum DataFileStatus {
  Created = 'CREATED',
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
  Error = 'ERROR'
}

export type DataFileType = {
  __typename?: 'DataFileType';
  id: Scalars['Int'];
  fileName: Scalars['String'];
  uploadedByUser: UserType;
  uploadedDateTime: Scalars['DateTime'];
  status: DataFileStatus;
  type: DataFileTypeType;
};

export type DataFileTypeType = {
  __typename?: 'DataFileTypeType';
  id: Scalars['Int'];
  name: Scalars['String'];
};



export enum FileFilterBy {
  Name = 'NAME',
  CreatedDateTime = 'CREATED_DATE_TIME'
}

export type FileFilterPropsType = {
  filters: Array<FileFilterType>;
};

export type FileFilterType = {
  filterBy: FileFilterBy;
  operation: FilterOperation;
  argument: Scalars['String'];
};

export type FileNoteType = {
  __typename?: 'FileNoteType';
  note: Scalars['String'];
  createdDateTime: Scalars['DateTimeOffset'];
  user: UserType;
};

export enum FileOrderBy {
  CreatedDateTime = 'CREATED_DATE_TIME',
  Name = 'NAME'
}

export type FilePageType = {
  __typename?: 'FilePageType';
  items: Array<FileType>;
  startCursor?: Maybe<Scalars['String']>;
  hasPreviousPage: Scalars['Boolean'];
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type FilePaginationPropsType = {
  pageSize: Scalars['Int'];
  orderBy: FileOrderBy;
  order: PaginationOrder;
};

export type FileType = {
  __typename?: 'FileType';
  id: Scalars['Guid'];
  name: Scalars['String'];
  createdDateTime: Scalars['DateTimeOffset'];
  createdByUser: UserType;
  formId: Scalars['Guid'];
  form: FormType;
  fileTypeId: Scalars['Guid'];
  connectedUsers?: Maybe<Array<UserType>>;
  connectedTeams?: Maybe<Array<TeamType>>;
  attachments?: Maybe<Array<AttachmentType>>;
  fileNotes: Array<FileNoteType>;
  myForms?: Maybe<Array<FormType>>;
  myTasks?: Maybe<Array<TaskType>>;
};

export type FileTypeType = {
  __typename?: 'FileTypeType';
  id: Scalars['Guid'];
  name: Scalars['String'];
  uiData: Scalars['String'];
  formTemplate: FormTemplateType;
};

export enum FilterOperation {
  Contains = 'CONTAINS',
  StartsWith = 'STARTS_WITH',
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN'
}

export type FormDataValueType = {
  __typename?: 'FormDataValueType';
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export enum FormEventType {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Submitted = 'SUBMITTED'
}

export enum FormFilterBy {
  Name = 'NAME',
  CreatedDateTime = 'CREATED_DATE_TIME',
  Field = 'FIELD'
}

export type FormFilterFieldValuesType = {
  __typename?: 'FormFilterFieldValuesType';
  fieldId: Scalars['ID'];
  label: Scalars['String'];
  options: Array<NameValueType>;
};

export type FormFilterPropsType = {
  filters: Array<FormFilterType>;
};

export type FormFilterType = {
  filterBy: FormFilterBy;
  operation: FilterOperation;
  argument: Scalars['String'];
};

export enum FormOrderBy {
  CreatedDateTime = 'CREATED_DATE_TIME',
  Name = 'NAME'
}

export type FormPageType = {
  __typename?: 'FormPageType';
  items: Array<FormType>;
  startCursor?: Maybe<Scalars['String']>;
  hasPreviousPage: Scalars['Boolean'];
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type FormPaginationPropsType = {
  pageSize: Scalars['Int'];
  orderBy: FormOrderBy;
  order: PaginationOrder;
};

export type FormTemplateFieldOptionDetailInputType = {
  formTemplateId: Scalars['ID'];
  fieldId: Scalars['ID'];
  index: Scalars['Int'];
  detail?: Maybe<Scalars['String']>;
};

export type FormTemplateFieldOptionDetailType = {
  __typename?: 'FormTemplateFieldOptionDetailType';
  formTemplateId: Scalars['ID'];
  fieldId: Scalars['ID'];
  index: Scalars['Int'];
  detail: Scalars['String'];
};

export type FormTemplateFieldRuleType = {
  __typename?: 'FormTemplateFieldRuleType';
  id: Scalars['Guid'];
  fieldId: Scalars['Guid'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  eventType: FormEventType;
  expectedValueJson: Scalars['String'];
  actionJson: Scalars['String'];
};

export enum FormTemplateFilterBy {
  Name = 'NAME'
}

export type FormTemplateFilterPropsType = {
  filters: Array<FormTemplateFilterType>;
};

export type FormTemplateFilterType = {
  filterBy: FormTemplateFilterBy;
  operation: FilterOperation;
  argument: Scalars['String'];
};

export enum FormTemplateOrderBy {
  Name = 'NAME'
}

export type FormTemplatePageType = {
  __typename?: 'FormTemplatePageType';
  items: Array<FormTemplateType>;
  startCursor?: Maybe<Scalars['String']>;
  hasPreviousPage: Scalars['Boolean'];
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type FormTemplatePaginationPropsType = {
  pageSize: Scalars['Int'];
  orderBy: FormTemplateOrderBy;
  order: PaginationOrder;
};

export type FormTemplateType = {
  __typename?: 'FormTemplateType';
  id: Scalars['Guid'];
  name: Scalars['String'];
  template: Scalars['String'];
  createdDateTime: Scalars['DateTimeOffset'];
  createdByUser: UserType;
  activeFormTemplateId: Scalars['Guid'];
  modules: Array<ModuleType>;
  siteIds: Array<Scalars['Int']>;
  rules?: Maybe<Array<FormTemplateFieldRuleType>>;
  queryDefinition?: Maybe<ReportQueryDefinitionType>;
  fieldOptionDetails?: Maybe<Array<FormTemplateFieldOptionDetailType>>;
  canSubmit?: Maybe<Scalars['Boolean']>;
  filterFields?: Maybe<Array<FormFilterFieldValuesType>>;
};

export type FormType = {
  __typename?: 'FormType';
  id: Scalars['Guid'];
  name: Scalars['String'];
  templateId: Scalars['Guid'];
  template: Scalars['String'];
  createdDateTime: Scalars['DateTimeOffset'];
  createdByUser: UserType;
  parentId?: Maybe<Scalars['Guid']>;
  formDataJson: Scalars['String'];
  lastUpdatedDateTime: Scalars['DateTimeOffset'];
  submittedDateTime?: Maybe<Scalars['DateTimeOffset']>;
  isSubmitted: Scalars['Boolean'];
  isConfidential: Scalars['Boolean'];
  formTemplate: FormTemplateType;
  filterFieldData: Array<FormDataValueType>;
};


export type IdentityType = {
  __typename?: 'IdentityType';
  email: Scalars['String'];
  customData?: Maybe<Scalars['String']>;
};

export type InvitationType = {
  __typename?: 'InvitationType';
  id: Scalars['Guid'];
  email: Scalars['String'];
  createdDateTime: Scalars['DateTimeOffset'];
  modules: Array<Module>;
  sites: Array<SiteAdminType>;
};

export type LibraryAncestorFolderType = {
  __typename?: 'LibraryAncestorFolderType';
  id: Scalars['Guid'];
  name: Scalars['String'];
};

export type LibraryFilesType = {
  __typename?: 'LibraryFilesType';
  files: Array<LibraryFileType>;
  downloadFileUrlTemplate: Scalars['String'];
};

export type LibraryFileType = {
  __typename?: 'LibraryFileType';
  id: Scalars['Guid'];
  name: Scalars['String'];
  createdDateTime: Scalars['DateTimeOffset'];
  createdByUser: UserType;
  folderId: Scalars['Guid'];
  folder: LibraryFolderType;
  ancestorFolders: Array<LibraryAncestorFolderType>;
  tags: Array<TagType>;
};

export type LibraryFolderType = {
  __typename?: 'LibraryFolderType';
  id: Scalars['Guid'];
  name: Scalars['String'];
  createdDateTime: Scalars['DateTimeOffset'];
  createdByUser: UserType;
  files: Array<LibraryFileType>;
  folders: Array<LibraryFolderType>;
  parentFolderId?: Maybe<Scalars['Guid']>;
  ancestorFolders: Array<LibraryAncestorFolderType>;
  downloadFileUrlTemplate: Scalars['String'];
};

export enum Module {
  TillrPlatform = 'TILLR_PLATFORM',
  FacilitiesMonitoring = 'FACILITIES_MONITORING',
  HealthAndSafety = 'HEALTH_AND_SAFETY',
  Gdpr = 'GDPR',
  RiskManagement = 'RISK_MANAGEMENT',
  PerformanceCoaching = 'PERFORMANCE_COACHING',
  QualityAssurance = 'QUALITY_ASSURANCE',
  Lite = 'LITE'
}

export type ModuleType = {
  __typename?: 'ModuleType';
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Module;
};

export type MutationGraphType = {
  __typename?: 'MutationGraphType';
  createAttachments?: Maybe<CreateAttachmentsResponseType>;
  updateAttachment?: Maybe<AttachmentType>;
  deleteAttachment?: Maybe<AttachmentType>;
  updateMyCustomData?: Maybe<IdentityType>;
  createDataFileType?: Maybe<DataFileTypeType>;
  createDataFile?: Maybe<CreateDataFileType>;
  createFile?: Maybe<FileType>;
  createFileNote?: Maybe<FileNoteType>;
  updateFile?: Maybe<FileType>;
  createForm?: Maybe<FormType>;
  updateForm?: Maybe<FormType>;
  updateFormTemplateFieldOptionDetail?: Maybe<FormTemplateFieldOptionDetailType>;
  assignFormTemplateToTenantModules?: Maybe<FormTemplateType>;
  assignFormTemplateToTenantSites?: Maybe<FormTemplateType>;
  updateFormTemplate?: Maybe<FormTemplateType>;
  createReport?: Maybe<SystemReportType>;
  updateReport?: Maybe<SystemReportType>;
  assignReportToTenantModules?: Maybe<TenantReportType>;
  createRole?: Maybe<RoleType>;
  updateRole?: Maybe<RoleType>;
  createLibraryFiles?: Maybe<CreateLibraryFilesResponseType>;
  deleteLibraryFile?: Maybe<LibraryFileType>;
  createLibraryFolder?: Maybe<LibraryFolderType>;
  deleteLibraryFolder?: Maybe<LibraryFolderType>;
  updateMyNotificationPreferences?: Maybe<Array<Maybe<UserNotificationPreferenceType>>>;
  createSite?: Maybe<SiteAdminType>;
  updateSite?: Maybe<SiteAdminType>;
  createTag?: Maybe<TagType>;
  deleteTag?: Maybe<TagType>;
  createTask?: Maybe<TaskType>;
  createTaskNote?: Maybe<TaskNoteType>;
  updateMyTask?: Maybe<TaskType>;
  closeMyTask?: Maybe<TaskType>;
  setMyTaskReminder?: Maybe<UserTaskReminderOverrideType>;
  updateReportUsers?: Maybe<TenantReportType>;
  createInvitation?: Maybe<InvitationType>;
  updateUser?: Maybe<UserAdminType>;
  updateUserCustomData?: Maybe<IdentityType>;
};


export type MutationGraphTypeCreateAttachmentsArgs = {
  module: Module;
  parentId: Scalars['Guid'];
  parentType: ParentType;
  fileNames: Array<Scalars['String']>;
};


export type MutationGraphTypeUpdateAttachmentArgs = {
  module: Module;
  attachment: UpdateAttachmentInputType;
};


export type MutationGraphTypeDeleteAttachmentArgs = {
  module: Module;
  id: Scalars['Guid'];
};


export type MutationGraphTypeUpdateMyCustomDataArgs = {
  customData?: Maybe<Scalars['String']>;
};


export type MutationGraphTypeCreateDataFileTypeArgs = {
  name: Scalars['String'];
};


export type MutationGraphTypeCreateDataFileArgs = {
  typeId: Scalars['Int'];
  fileName: Scalars['String'];
};


export type MutationGraphTypeCreateFileArgs = {
  siteId: Scalars['Int'];
  module: Module;
  fileTypeId: Scalars['Guid'];
  form: CreateFormInputType;
};


export type MutationGraphTypeCreateFileNoteArgs = {
  siteId: Scalars['Int'];
  module: Module;
  fileId: Scalars['Guid'];
  note: Scalars['String'];
};


export type MutationGraphTypeUpdateFileArgs = {
  siteId: Scalars['Int'];
  module: Module;
  fileId: Scalars['Guid'];
  form: UpdateFormInputType;
};


export type MutationGraphTypeCreateFormArgs = {
  siteId: Scalars['Int'];
  module: Module;
  form: CreateFormInputType;
  createTasks?: Maybe<Scalars['Boolean']>;
};


export type MutationGraphTypeUpdateFormArgs = {
  siteId: Scalars['Int'];
  module: Module;
  form: UpdateFormInputType;
  createTasks?: Maybe<Scalars['Boolean']>;
};


export type MutationGraphTypeUpdateFormTemplateFieldOptionDetailArgs = {
  fieldOptionDetail: FormTemplateFieldOptionDetailInputType;
};


export type MutationGraphTypeAssignFormTemplateToTenantModulesArgs = {
  formTemplateId: Scalars['ID'];
  tenantId: Scalars['Int'];
  modules: Array<Module>;
};


export type MutationGraphTypeAssignFormTemplateToTenantSitesArgs = {
  formTemplateId: Scalars['ID'];
  tenantId: Scalars['Int'];
  siteIds: Array<Scalars['Int']>;
};


export type MutationGraphTypeUpdateFormTemplateArgs = {
  formTemplate: UpdateFormTemplateInputType;
  newFormTemplateId?: Maybe<Scalars['Guid']>;
};


export type MutationGraphTypeCreateReportArgs = {
  report: CreateReportInputType;
};


export type MutationGraphTypeUpdateReportArgs = {
  report: UpdateReportInputType;
};


export type MutationGraphTypeAssignReportToTenantModulesArgs = {
  reportId: Scalars['ID'];
  tenantId: Scalars['Int'];
  modules: Array<Module>;
};


export type MutationGraphTypeCreateRoleArgs = {
  tenantId: Scalars['Int'];
  role: CreateRoleInputType;
};


export type MutationGraphTypeUpdateRoleArgs = {
  tenantId: Scalars['Int'];
  role: UpdateRoleInputType;
};


export type MutationGraphTypeCreateLibraryFilesArgs = {
  folderId: Scalars['Guid'];
  fileNames: Array<Scalars['String']>;
};


export type MutationGraphTypeDeleteLibraryFileArgs = {
  folderId: Scalars['Guid'];
  fileId: Scalars['Guid'];
};


export type MutationGraphTypeCreateLibraryFolderArgs = {
  parentFolderId: Scalars['Guid'];
  name: Scalars['String'];
};


export type MutationGraphTypeDeleteLibraryFolderArgs = {
  folderId: Scalars['Guid'];
};


export type MutationGraphTypeUpdateMyNotificationPreferencesArgs = {
  preferences: Array<UserNotificationPreferenceInputType>;
};


export type MutationGraphTypeCreateSiteArgs = {
  parentSiteId?: Maybe<Scalars['Int']>;
  site: CreateSiteInputType;
};


export type MutationGraphTypeUpdateSiteArgs = {
  site: UpdateSiteInputType;
};


export type MutationGraphTypeCreateTagArgs = {
  module?: Maybe<Module>;
  tag: CreateTagInputType;
};


export type MutationGraphTypeDeleteTagArgs = {
  tagId: Scalars['Guid'];
};


export type MutationGraphTypeCreateTaskArgs = {
  siteId: Scalars['Int'];
  module: Module;
  task: CreateTaskInputType;
};


export type MutationGraphTypeCreateTaskNoteArgs = {
  siteId: Scalars['Int'];
  module: Module;
  taskId: Scalars['Guid'];
  note: Scalars['String'];
};


export type MutationGraphTypeUpdateMyTaskArgs = {
  siteId: Scalars['Int'];
  module: Module;
  task: UpdateTaskInputType;
  note?: Maybe<Scalars['String']>;
};


export type MutationGraphTypeCloseMyTaskArgs = {
  siteId: Scalars['Int'];
  module: Module;
  taskId: Scalars['Guid'];
  completedDateTime: Scalars['DateTimeOffset'];
};


export type MutationGraphTypeSetMyTaskReminderArgs = {
  taskId: Scalars['Guid'];
  reminderPeriodInHours?: Maybe<Scalars['Int']>;
};


export type MutationGraphTypeUpdateReportUsersArgs = {
  reportId: Scalars['Guid'];
  userIds: Array<Scalars['Guid']>;
};


export type MutationGraphTypeCreateInvitationArgs = {
  invitation: CreateInvitationInputType;
};


export type MutationGraphTypeUpdateUserArgs = {
  user: UpdateUserInputType;
};


export type MutationGraphTypeUpdateUserCustomDataArgs = {
  user: UpdateUserCustomDataInputType;
};

export type NameTypeType = {
  __typename?: 'NameTypeType';
  name: Scalars['String'];
  type: Scalars['String'];
};

export type NameValueType = {
  __typename?: 'NameValueType';
  name: Scalars['String'];
  value: Scalars['String'];
};

export enum NotificationType {
  TaskAssigned = 'TASK_ASSIGNED',
  TaskOverdueNotifyAssignee = 'TASK_OVERDUE_NOTIFY_ASSIGNEE',
  TaskOverdueNotifyCreator = 'TASK_OVERDUE_NOTIFY_CREATOR',
  TaskClosed = 'TASK_CLOSED',
  TaskReminder = 'TASK_REMINDER',
  TaskNoteAdded = 'TASK_NOTE_ADDED'
}

export type NotificationTypeType = {
  __typename?: 'NotificationTypeType';
  value: NotificationType;
  description: Scalars['String'];
  defaultValue: Scalars['Boolean'];
};

export enum PaginationOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ParentType {
  File = 'FILE',
  Form = 'FORM',
  Task = 'TASK',
  FormTemplate = 'FORM_TEMPLATE',
  LibraryFile = 'LIBRARY_FILE'
}

export type QueryGraphType = {
  __typename?: 'QueryGraphType';
  downloadAttachmentUrlTemplate?: Maybe<Scalars['String']>;
  attachment?: Maybe<AttachmentType>;
  myIdentity?: Maybe<IdentityType>;
  myTenant?: Maybe<TenantType>;
  mySites?: Maybe<Array<SiteType>>;
  dataFileTypes?: Maybe<Array<DataFileTypeType>>;
  dataFile?: Maybe<DataFileType>;
  dataFiles?: Maybe<Array<DataFileType>>;
  fileType?: Maybe<FileTypeType>;
  fileTypes?: Maybe<Array<FileTypeType>>;
  myFile?: Maybe<FileType>;
  myFiles?: Maybe<FilePageType>;
  formTemplate?: Maybe<FormTemplateType>;
  formTemplates?: Maybe<FormTemplatePageType>;
  form?: Maybe<FormType>;
  forms?: Maybe<FormPageType>;
  systemFormTemplate?: Maybe<FormTemplateType>;
  systemFormTemplates?: Maybe<FormTemplatePageType>;
  systemReport?: Maybe<SystemReportType>;
  systemReports?: Maybe<Array<SystemReportType>>;
  tenant?: Maybe<TenantType>;
  tenants?: Maybe<Array<TenantType>>;
  libraryFolder?: Maybe<LibraryFolderType>;
  libraryFiles?: Maybe<LibraryFilesType>;
  tenantReport?: Maybe<TenantReportType>;
  tenantReports?: Maybe<Array<TenantReportType>>;
  myReport?: Maybe<SystemReportType>;
  myReports?: Maybe<Array<ReportType>>;
  site?: Maybe<SiteType>;
  siteAndChildren?: Maybe<Array<SiteType>>;
  siteAdmin?: Maybe<SiteAdminType>;
  sitesAdmin?: Maybe<Array<SiteAdminType>>;
  sites?: Maybe<Array<SiteType>>;
  tags?: Maybe<Array<Scalars['String']>>;
  taggedEntities?: Maybe<Array<TaggedEntityType>>;
  myTask?: Maybe<TaskType>;
  myTasks?: Maybe<TaskPageType>;
  myNotificationPreferences?: Maybe<Array<UserNotificationPreferenceType>>;
  notificationTypes?: Maybe<Array<NotificationTypeType>>;
  myTaskReminder?: Maybe<UserTaskReminderOverrideType>;
  buildNumber: Scalars['String'];
  roles?: Maybe<Array<RoleType>>;
  userAdmin?: Maybe<UserAdminType>;
  usersAdmin?: Maybe<Array<UserAdminType>>;
  pendingInvitations?: Maybe<Array<InvitationType>>;
  team?: Maybe<TeamType>;
  teams?: Maybe<Array<TeamType>>;
  users?: Maybe<Array<UserType>>;
};


export type QueryGraphTypeAttachmentArgs = {
  module: Module;
  id: Scalars['Guid'];
};


export type QueryGraphTypeDataFileArgs = {
  id: Scalars['Int'];
};


export type QueryGraphTypeFileTypeArgs = {
  module: Module;
  id: Scalars['Guid'];
};


export type QueryGraphTypeFileTypesArgs = {
  module: Module;
};


export type QueryGraphTypeMyFileArgs = {
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
};


export type QueryGraphTypeMyFilesArgs = {
  siteId: Scalars['Int'];
  module: Module;
  fileTypeId: Scalars['Guid'];
  filterProps?: Maybe<FileFilterPropsType>;
  paginationProps: FilePaginationPropsType;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryGraphTypeFormTemplateArgs = {
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
};


export type QueryGraphTypeFormTemplatesArgs = {
  siteId: Scalars['Int'];
  module: Module;
  filterProps?: Maybe<FormTemplateFilterPropsType>;
  paginationProps: FormTemplatePaginationPropsType;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryGraphTypeFormArgs = {
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
};


export type QueryGraphTypeFormsArgs = {
  siteId: Scalars['Int'];
  module: Module;
  formTemplateId: Scalars['Guid'];
  filterProps?: Maybe<FormFilterPropsType>;
  paginationProps: FormPaginationPropsType;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryGraphTypeSystemFormTemplateArgs = {
  id: Scalars['Guid'];
};


export type QueryGraphTypeSystemFormTemplatesArgs = {
  filterProps?: Maybe<FormTemplateFilterPropsType>;
  paginationProps: FormTemplatePaginationPropsType;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryGraphTypeSystemReportArgs = {
  id: Scalars['Guid'];
};


export type QueryGraphTypeTenantArgs = {
  id: Scalars['Int'];
};


export type QueryGraphTypeLibraryFolderArgs = {
  folderId: Scalars['Guid'];
};


export type QueryGraphTypeLibraryFilesArgs = {
  searchQuery: Scalars['String'];
};


export type QueryGraphTypeTenantReportArgs = {
  id: Scalars['Guid'];
};


export type QueryGraphTypeMyReportArgs = {
  module: Module;
  id: Scalars['Guid'];
};


export type QueryGraphTypeMyReportsArgs = {
  module: Module;
};


export type QueryGraphTypeSiteArgs = {
  id: Scalars['Int'];
};


export type QueryGraphTypeSiteAndChildrenArgs = {
  id: Scalars['Int'];
};


export type QueryGraphTypeSiteAdminArgs = {
  id: Scalars['Int'];
};


export type QueryGraphTypeSitesArgs = {
  type?: Maybe<Scalars['String']>;
  parentSiteId?: Maybe<Scalars['Int']>;
};


export type QueryGraphTypeTaggedEntitiesArgs = {
  module: Module;
  parentType: ParentType;
  tagValue: Scalars['String'];
};


export type QueryGraphTypeMyTaskArgs = {
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
};


export type QueryGraphTypeMyTasksArgs = {
  siteId: Scalars['Int'];
  module: Module;
  filterProps?: Maybe<TaskFilterPropsType>;
  paginationProps: TaskPaginationPropsType;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryGraphTypeMyTaskReminderArgs = {
  taskId: Scalars['Guid'];
};


export type QueryGraphTypeUserAdminArgs = {
  id: Scalars['Guid'];
};


export type QueryGraphTypeUsersAdminArgs = {
  siteId?: Maybe<Scalars['Int']>;
};


export type QueryGraphTypePendingInvitationsArgs = {
  siteId?: Maybe<Scalars['Int']>;
};


export type QueryGraphTypeTeamArgs = {
  module: Module;
  id: Scalars['Guid'];
};


export type QueryGraphTypeTeamsArgs = {
  module: Module;
};


export type QueryGraphTypeUsersArgs = {
  siteId: Scalars['Int'];
  module: Module;
};

export enum ReportingDatabase {
  System = 'SYSTEM',
  Tenant = 'TENANT'
}

export type ReportQueryDefinitionType = {
  __typename?: 'ReportQueryDefinitionType';
  inputParameters: Array<NameTypeType>;
  resultColumns: Array<NameTypeType>;
};

export type ReportType = {
  __typename?: 'ReportType';
  id: Scalars['Guid'];
  name: Scalars['String'];
};

export type RoleType = {
  __typename?: 'RoleType';
  id: Scalars['Guid'];
  name: Scalars['String'];
  permissions: Array<Scalars['String']>;
};

export type SiteAdminType = {
  __typename?: 'SiteAdminType';
  id: Scalars['Int'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  parentSiteId?: Maybe<Scalars['Int']>;
  topParentSite?: Maybe<SiteType>;
  externalId?: Maybe<Scalars['String']>;
  users?: Maybe<Array<UserAdminType>>;
  pendingInvitations?: Maybe<Array<InvitationType>>;
};

export type SiteType = {
  __typename?: 'SiteType';
  id: Scalars['Int'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  parentSiteId?: Maybe<Scalars['Int']>;
  topParentSite?: Maybe<SiteType>;
};

export type SystemReportType = {
  __typename?: 'SystemReportType';
  id: Scalars['Guid'];
  name: Scalars['String'];
  isPublished: Scalars['Boolean'];
  reportingDatabase: ReportingDatabase;
  sqlQuery?: Maybe<Scalars['String']>;
  uiData?: Maybe<Scalars['String']>;
  queryDefinition?: Maybe<ReportQueryDefinitionType>;
};

export type TaggedEntityType = {
  __typename?: 'TaggedEntityType';
  id: Scalars['Guid'];
  parentType: ParentType;
  name: Scalars['String'];
};

export type TagType = {
  __typename?: 'TagType';
  id: Scalars['Guid'];
  parentType: ParentType;
  parentId: Scalars['Guid'];
  value: Scalars['String'];
};

export type TaskAssigneeInputType = {
  assigneeId: Scalars['Guid'];
  assigneeType: AssigneeType;
};

export type TaskAssigneeType = {
  __typename?: 'TaskAssigneeType';
  assigneeId: Scalars['Guid'];
  assigneeType: AssigneeType;
};

export enum TaskFilterBy {
  Name = 'NAME',
  Assignee = 'ASSIGNEE',
  Closed = 'CLOSED',
  DueDate = 'DUE_DATE'
}

export type TaskFilterPropsType = {
  filters: Array<TaskFilterType>;
};

export type TaskFilterType = {
  filterBy: TaskFilterBy;
  operation: FilterOperation;
  argument: Scalars['String'];
};

export type TaskNoteType = {
  __typename?: 'TaskNoteType';
  note: Scalars['String'];
  createdDateTime: Scalars['DateTimeOffset'];
  user: UserType;
};

export enum TaskOrderBy {
  DueDate = 'DUE_DATE',
  Name = 'NAME',
  ReferenceNumber = 'REFERENCE_NUMBER'
}

export type TaskPageType = {
  __typename?: 'TaskPageType';
  items: Array<TaskType>;
  startCursor?: Maybe<Scalars['String']>;
  hasPreviousPage: Scalars['Boolean'];
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type TaskPaginationPropsType = {
  pageSize: Scalars['Int'];
  orderBy: TaskOrderBy;
  order: PaginationOrder;
};

export type TaskType = {
  __typename?: 'TaskType';
  id: Scalars['Guid'];
  referenceNumber: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  createdDateTime: Scalars['DateTimeOffset'];
  dueDate: Scalars['DateTimeOffset'];
  open: Scalars['Boolean'];
  completedDate?: Maybe<Scalars['DateTimeOffset']>;
  createdByUser: UserType;
  createdByFormTemplateName?: Maybe<Scalars['String']>;
  assignees: Array<TaskAssigneeType>;
  assignedToUsers: Array<UserType>;
  assignedToTeams: Array<TeamType>;
  attachments: Array<AttachmentType>;
  notes: Array<TaskNoteType>;
};

export type TeamType = {
  __typename?: 'TeamType';
  id: Scalars['Guid'];
  name: Scalars['String'];
  users?: Maybe<Array<UserType>>;
};

export type TenantReportType = {
  __typename?: 'TenantReportType';
  id: Scalars['Guid'];
  name: Scalars['String'];
  modules: Array<ModuleType>;
  users?: Maybe<Array<UserType>>;
};

export enum TenantStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED'
}

export type TenantType = {
  __typename?: 'TenantType';
  id: Scalars['Int'];
  name: Scalars['String'];
  status: TenantStatus;
  createdDateTime: Scalars['DateTimeOffset'];
  clientId?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  uiData?: Maybe<Scalars['String']>;
  modules: Array<ModuleType>;
  formTemplates?: Maybe<Array<FormTemplateType>>;
  reports?: Maybe<Array<TenantReportType>>;
  roles?: Maybe<Array<RoleType>>;
};

export type UpdateAttachmentInputType = {
  id: Scalars['Guid'];
  name: Scalars['String'];
};

export type UpdateFormInputType = {
  id: Scalars['Guid'];
  name: Scalars['String'];
  formDataJson: Scalars['String'];
  isSubmitted: Scalars['Boolean'];
};

export type UpdateFormTemplateInputType = {
  id: Scalars['Guid'];
  name: Scalars['String'];
  template: Scalars['String'];
};

export type UpdateReportInputType = {
  id: Scalars['Guid'];
  name: Scalars['String'];
  isPublished: Scalars['Boolean'];
  reportingDatabase: ReportingDatabase;
  sqlQuery: Scalars['String'];
  uiData?: Maybe<Scalars['String']>;
};

export type UpdateRoleInputType = {
  id: Scalars['Guid'];
  name: Scalars['String'];
  permissions: Array<Scalars['String']>;
};

export type UpdateSiteInputType = {
  id: Scalars['Int'];
  name: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  parentSiteId?: Maybe<Scalars['Int']>;
};

export type UpdateTaskInputType = {
  id: Scalars['Guid'];
  name: Scalars['String'];
  description: Scalars['String'];
  open: Scalars['Boolean'];
  completedDate?: Maybe<Scalars['DateTimeOffset']>;
  assignees: Array<TaskAssigneeInputType>;
};

export type UpdateUserCustomDataInputType = {
  userId: Scalars['Guid'];
  customData?: Maybe<Scalars['String']>;
};

export type UpdateUserInputType = {
  userId: Scalars['Guid'];
  roleId: Scalars['Guid'];
  modules: Array<Module>;
  siteIds: Array<Scalars['Int']>;
};

export type UserAdminType = {
  __typename?: 'UserAdminType';
  id: Scalars['Guid'];
  name: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  identity: IdentityType;
  modules: Array<Module>;
  role: RoleType;
  sites: Array<SiteAdminType>;
};

export type UserNotificationPreferenceInputType = {
  notificationType: NotificationType;
  isEnabled: Scalars['Boolean'];
  customData?: Maybe<Scalars['String']>;
};

export type UserNotificationPreferenceType = {
  __typename?: 'UserNotificationPreferenceType';
  notificationType: NotificationType;
  description: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  customData?: Maybe<Scalars['String']>;
};

export type UserTaskReminderOverrideType = {
  __typename?: 'UserTaskReminderOverrideType';
  taskId: Scalars['ID'];
  reminderPeriodInHours: Scalars['Int'];
};

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['Guid'];
  name: Scalars['String'];
};

export type GetTenantReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantReportsQuery = (
  { __typename?: 'QueryGraphType' }
  & { tenantReports?: Maybe<Array<(
    { __typename?: 'TenantReportType' }
    & Pick<TenantReportType, 'id' | 'name'>
    & { users?: Maybe<Array<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'name'>
    )>> }
  )>> }
);

export type GetTenantReportWithUsersQueryVariables = Exact<{
  id: Scalars['Guid'];
}>;


export type GetTenantReportWithUsersQuery = (
  { __typename?: 'QueryGraphType' }
  & { tenantReport?: Maybe<(
    { __typename?: 'TenantReportType' }
    & TenantReportWithUsersFragment
  )>, usersAdmin?: Maybe<Array<(
    { __typename?: 'UserAdminType' }
    & Pick<UserAdminType, 'id' | 'name'>
    & { role: (
      { __typename?: 'RoleType' }
      & Pick<RoleType, 'permissions'>
    ) }
  )>> }
);

export type UpdateReportUsersMutationVariables = Exact<{
  reportId: Scalars['Guid'];
  userIds: Array<Scalars['Guid']> | Scalars['Guid'];
}>;


export type UpdateReportUsersMutation = (
  { __typename?: 'MutationGraphType' }
  & { updateReportUsers?: Maybe<(
    { __typename?: 'TenantReportType' }
    & TenantReportWithUsersFragment
  )> }
);

export type TenantReportWithUsersFragment = (
  { __typename?: 'TenantReportType' }
  & Pick<TenantReportType, 'id' | 'name'>
  & { users?: Maybe<Array<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id'>
  )>> }
);

export type GetFormTemplateRulesQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetFormTemplateRulesQuery = (
  { __typename?: 'QueryGraphType' }
  & { formTemplate?: Maybe<(
    { __typename?: 'FormTemplateType' }
    & Pick<FormTemplateType, 'name' | 'template'>
    & { rules?: Maybe<Array<(
      { __typename?: 'FormTemplateFieldRuleType' }
      & Pick<FormTemplateFieldRuleType, 'id' | 'fieldId' | 'name' | 'isActive' | 'eventType' | 'expectedValueJson' | 'actionJson'>
    )>> }
  )> }
);

export type GetSitesAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSitesAdminQuery = (
  { __typename?: 'QueryGraphType' }
  & { sitesAdmin?: Maybe<Array<(
    { __typename?: 'SiteAdminType' }
    & SiteListItemFragment
  )>> }
);

export type CreateSiteMutationVariables = Exact<{
  parentSiteId: Scalars['Int'];
  site: CreateSiteInputType;
}>;


export type CreateSiteMutation = (
  { __typename?: 'MutationGraphType' }
  & { createSite?: Maybe<(
    { __typename?: 'SiteAdminType' }
    & SiteListItemFragment
  )> }
);

export type SiteListItemFragment = (
  { __typename?: 'SiteAdminType' }
  & Pick<SiteAdminType, 'id' | 'name' | 'externalId' | 'parentSiteId'>
);

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = (
  { __typename?: 'QueryGraphType' }
  & { roles?: Maybe<Array<(
    { __typename?: 'RoleType' }
    & RoleListItemFragment
  )>> }
);

export type RoleListItemFragment = (
  { __typename?: 'RoleType' }
  & Pick<RoleType, 'id' | 'name'>
);

export type GetUsersAndTeamsQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
}>;


export type GetUsersAndTeamsQuery = (
  { __typename?: 'QueryGraphType' }
  & { users?: Maybe<Array<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  )>>, teams?: Maybe<Array<(
    { __typename?: 'TeamType' }
    & Pick<TeamType, 'id' | 'name'>
    & { users?: Maybe<Array<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
    )>> }
  )>> }
);

export type GetUsersAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersAdminQuery = (
  { __typename?: 'QueryGraphType' }
  & { usersAdmin?: Maybe<Array<(
    { __typename?: 'UserAdminType' }
    & RegisteredUserFragment
  )>>, pendingInvitations?: Maybe<Array<(
    { __typename?: 'InvitationType' }
    & PendingInvitationFragment
  )>> }
);

export type GetUserAdminQueryVariables = Exact<{
  id: Scalars['Guid'];
}>;


export type GetUserAdminQuery = (
  { __typename?: 'QueryGraphType' }
  & { userAdmin?: Maybe<(
    { __typename?: 'UserAdminType' }
    & UserFragment
  )> }
);

export type CreateInvitationMutationVariables = Exact<{
  invitation: CreateInvitationInputType;
}>;


export type CreateInvitationMutation = (
  { __typename?: 'MutationGraphType' }
  & { createInvitation?: Maybe<(
    { __typename?: 'InvitationType' }
    & PendingInvitationFragment
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  user: UpdateUserInputType;
}>;


export type UpdateUserMutation = (
  { __typename?: 'MutationGraphType' }
  & { updateUser?: Maybe<(
    { __typename?: 'UserAdminType' }
    & UserFragment
  )> }
);

export type RegisteredUserFragment = (
  { __typename?: 'UserAdminType' }
  & Pick<UserAdminType, 'id' | 'name' | 'email'>
  & { role: (
    { __typename?: 'RoleType' }
    & Pick<RoleType, 'id' | 'name'>
  ), sites: Array<(
    { __typename?: 'SiteAdminType' }
    & Pick<SiteAdminType, 'id' | 'name'>
  )> }
);

export type PendingInvitationFragment = (
  { __typename?: 'InvitationType' }
  & Pick<InvitationType, 'email' | 'createdDateTime'>
  & { sites: Array<(
    { __typename?: 'SiteAdminType' }
    & Pick<SiteAdminType, 'id' | 'name'>
  )> }
);

export type UserFragment = (
  { __typename?: 'UserAdminType' }
  & Pick<UserAdminType, 'id' | 'firstName' | 'lastName' | 'email' | 'modules'>
  & { role: (
    { __typename?: 'RoleType' }
    & Pick<RoleType, 'id' | 'name' | 'permissions'>
  ), sites: Array<(
    { __typename?: 'SiteAdminType' }
    & Pick<SiteAdminType, 'id' | 'name'>
  )> }
);

export type GetTeamsAndUsersQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
}>;


export type GetTeamsAndUsersQuery = (
  { __typename?: 'QueryGraphType' }
  & { teams?: Maybe<Array<(
    { __typename?: 'TeamType' }
    & Pick<TeamType, 'id' | 'name'>
  )>>, users?: Maybe<Array<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  )>> }
);

export type CreateAttachmentsMutationVariables = Exact<{
  module: Module;
  parentId: Scalars['Guid'];
  parentType: ParentType;
  fileNames: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateAttachmentsMutation = (
  { __typename?: 'MutationGraphType' }
  & { createAttachments?: Maybe<(
    { __typename?: 'CreateAttachmentsResponseType' }
    & Pick<CreateAttachmentsResponseType, 'uploadUrlTemplate'>
    & { attachments: Array<(
      { __typename?: 'AttachmentType' }
      & AttachmentFragment
    )> }
  )> }
);

export type UpdateAttachmentMutationVariables = Exact<{
  module: Module;
  id: Scalars['Guid'];
  name: Scalars['String'];
}>;


export type UpdateAttachmentMutation = (
  { __typename?: 'MutationGraphType' }
  & { updateAttachment?: Maybe<(
    { __typename?: 'AttachmentType' }
    & AttachmentFragment
  )> }
);

export type DeleteAttachmentMutationVariables = Exact<{
  module: Module;
  id: Scalars['Guid'];
}>;


export type DeleteAttachmentMutation = (
  { __typename?: 'MutationGraphType' }
  & { deleteAttachment?: Maybe<(
    { __typename?: 'AttachmentType' }
    & Pick<AttachmentType, 'id'>
  )> }
);

export type GetDownloadAttachmentUrlTemplateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDownloadAttachmentUrlTemplateQuery = (
  { __typename?: 'QueryGraphType' }
  & Pick<QueryGraphType, 'downloadAttachmentUrlTemplate'>
);

export type GetAttachmentDetailQueryVariables = Exact<{
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetAttachmentDetailQuery = (
  { __typename?: 'QueryGraphType' }
  & Pick<QueryGraphType, 'downloadAttachmentUrlTemplate'>
  & { attachment?: Maybe<(
    { __typename?: 'AttachmentType' }
    & AttachmentFragment
  )> }
);

export type GetAttachmentUrlQueryVariables = Exact<{
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetAttachmentUrlQuery = (
  { __typename?: 'QueryGraphType' }
  & Pick<QueryGraphType, 'downloadAttachmentUrlTemplate'>
  & { attachment?: Maybe<(
    { __typename?: 'AttachmentType' }
    & Pick<AttachmentType, 'blobStorageId' | 'fileName' | 'name'>
  )> }
);

export type AttachmentFragment = (
  { __typename?: 'AttachmentType' }
  & Pick<AttachmentType, 'id' | 'blobStorageId' | 'fileName' | 'name' | 'createdDateTime' | 'parentId' | 'parentType'>
  & { createdByUser: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'name'>
  ) }
);

export type GetBuildNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBuildNumberQuery = (
  { __typename?: 'QueryGraphType' }
  & Pick<QueryGraphType, 'buildNumber'>
);

export type GetFileTypesQueryVariables = Exact<{
  module: Module;
}>;


export type GetFileTypesQuery = (
  { __typename?: 'QueryGraphType' }
  & { fileTypes?: Maybe<Array<(
    { __typename?: 'FileTypeType' }
    & Pick<FileTypeType, 'id' | 'name' | 'uiData'>
  )>> }
);

export type GetFileTypeQueryVariables = Exact<{
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetFileTypeQuery = (
  { __typename?: 'QueryGraphType' }
  & { fileType?: Maybe<(
    { __typename?: 'FileTypeType' }
    & FileTypeFragment
  )> }
);

export type GetMyFilesQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  fileTypeId: Scalars['Guid'];
  filterProps?: Maybe<FileFilterPropsType>;
  paginationProps: FilePaginationPropsType;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetMyFilesQuery = (
  { __typename?: 'QueryGraphType' }
  & { fileType?: Maybe<(
    { __typename?: 'FileTypeType' }
    & FileTypeFragment
  )>, myFiles?: Maybe<(
    { __typename?: 'FilePageType' }
    & Pick<FilePageType, 'startCursor' | 'hasPreviousPage' | 'endCursor' | 'hasNextPage'>
    & { items: Array<(
      { __typename?: 'FileType' }
      & FileListItemFragment
    )> }
  )> }
);

export type GetMyFileQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetMyFileQuery = (
  { __typename?: 'QueryGraphType' }
  & { myFile?: Maybe<(
    { __typename?: 'FileType' }
    & FileFragment
  )> }
);

export type CreateFileMutationVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  fileTypeId: Scalars['Guid'];
  form: CreateFormInputType;
}>;


export type CreateFileMutation = (
  { __typename?: 'MutationGraphType' }
  & { createFile?: Maybe<(
    { __typename?: 'FileType' }
    & FileListItemFragment
  )> }
);

export type CreateFileNoteMutationVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  fileId: Scalars['Guid'];
  note: Scalars['String'];
}>;


export type CreateFileNoteMutation = (
  { __typename?: 'MutationGraphType' }
  & { createFileNote?: Maybe<(
    { __typename?: 'FileNoteType' }
    & FileNoteFragment
  )> }
);

export type UpdateFileMutationVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  fileId: Scalars['Guid'];
  form: UpdateFormInputType;
}>;


export type UpdateFileMutation = (
  { __typename?: 'MutationGraphType' }
  & { updateFile?: Maybe<(
    { __typename?: 'FileType' }
    & FileFragment
  )> }
);

export type FileTypeFragment = (
  { __typename?: 'FileTypeType' }
  & Pick<FileTypeType, 'id' | 'name'>
  & { formTemplate: (
    { __typename?: 'FormTemplateType' }
    & Pick<FormTemplateType, 'id' | 'template'>
  ) }
);

export type FileListItemFragment = (
  { __typename?: 'FileType' }
  & Pick<FileType, 'name' | 'createdDateTime' | 'id'>
  & { createdByUser: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'name'>
  ) }
);

export type FileFragment = (
  { __typename?: 'FileType' }
  & Pick<FileType, 'name' | 'createdDateTime' | 'formId' | 'fileTypeId'>
  & { createdByUser: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  ), form: (
    { __typename?: 'FormType' }
    & Pick<FormType, 'id' | 'template' | 'formDataJson'>
  ), connectedTeams?: Maybe<Array<(
    { __typename?: 'TeamType' }
    & Pick<TeamType, 'id' | 'name'>
  )>>, connectedUsers?: Maybe<Array<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  )>>, attachments?: Maybe<Array<(
    { __typename?: 'AttachmentType' }
    & Pick<AttachmentType, 'id' | 'blobStorageId' | 'fileName' | 'name' | 'createdDateTime'>
    & { createdByUser: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'name'>
    ) }
  )>>, fileNotes: Array<(
    { __typename?: 'FileNoteType' }
    & FileNoteFragment
  )>, myForms?: Maybe<Array<(
    { __typename?: 'FormType' }
    & Pick<FormType, 'id' | 'name' | 'createdDateTime'>
  )>>, myTasks?: Maybe<Array<(
    { __typename?: 'TaskType' }
    & Pick<TaskType, 'id' | 'referenceNumber' | 'name' | 'description' | 'dueDate' | 'open'>
    & { assignedToUsers: Array<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'name'>
    )>, assignedToTeams: Array<(
      { __typename?: 'TeamType' }
      & Pick<TeamType, 'name'>
    )> }
  )>> }
);

export type FileNoteFragment = (
  { __typename?: 'FileNoteType' }
  & Pick<FileNoteType, 'note' | 'createdDateTime'>
  & { user: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'name'>
  ) }
);

export type GetFormTemplatesQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  filterProps?: Maybe<FormTemplateFilterPropsType>;
  paginationProps: FormTemplatePaginationPropsType;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetFormTemplatesQuery = (
  { __typename?: 'QueryGraphType' }
  & { formTemplates?: Maybe<(
    { __typename?: 'FormTemplatePageType' }
    & Pick<FormTemplatePageType, 'startCursor' | 'hasPreviousPage' | 'endCursor' | 'hasNextPage'>
    & { items: Array<(
      { __typename?: 'FormTemplateType' }
      & FormTemplateListItemFragment
    )> }
  )> }
);

export type GetFormTemplatesByTagQueryVariables = Exact<{
  module: Module;
  tagValue: Scalars['String'];
}>;


export type GetFormTemplatesByTagQuery = (
  { __typename?: 'QueryGraphType' }
  & { taggedEntities?: Maybe<Array<(
    { __typename?: 'TaggedEntityType' }
    & FormTemplateByTagListItemFragment
  )>> }
);

export type GetFormTemplateQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetFormTemplateQuery = (
  { __typename?: 'QueryGraphType' }
  & { formTemplate?: Maybe<(
    { __typename?: 'FormTemplateType' }
    & Pick<FormTemplateType, 'id' | 'name' | 'template' | 'activeFormTemplateId'>
  )> }
);

export type GetFormTemplateWithFilterFieldsQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetFormTemplateWithFilterFieldsQuery = (
  { __typename?: 'QueryGraphType' }
  & { formTemplate?: Maybe<(
    { __typename?: 'FormTemplateType' }
    & Pick<FormTemplateType, 'id' | 'name' | 'canSubmit'>
    & { filterFields?: Maybe<Array<(
      { __typename?: 'FormFilterFieldValuesType' }
      & Pick<FormFilterFieldValuesType, 'fieldId' | 'label'>
      & { options: Array<(
        { __typename?: 'NameValueType' }
        & Pick<NameValueType, 'name' | 'value'>
      )> }
    )>> }
  )> }
);

export type GetFormTemplateAndInstancesQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  formTemplateId: Scalars['Guid'];
  filterProps?: Maybe<FormFilterPropsType>;
  paginationProps: FormPaginationPropsType;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetFormTemplateAndInstancesQuery = (
  { __typename?: 'QueryGraphType' }
  & { forms?: Maybe<(
    { __typename?: 'FormPageType' }
    & Pick<FormPageType, 'startCursor' | 'hasPreviousPage' | 'endCursor' | 'hasNextPage'>
    & { items: Array<(
      { __typename?: 'FormType' }
      & FormFragment
    )> }
  )>, formTemplate?: Maybe<(
    { __typename?: 'FormTemplateType' }
    & Pick<FormTemplateType, 'name' | 'template'>
  )> }
);

export type GetFormsQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  formTemplateId: Scalars['Guid'];
  filterProps?: Maybe<FormFilterPropsType>;
  paginationProps: FormPaginationPropsType;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetFormsQuery = (
  { __typename?: 'QueryGraphType' }
  & { forms?: Maybe<(
    { __typename?: 'FormPageType' }
    & Pick<FormPageType, 'startCursor' | 'hasPreviousPage' | 'endCursor' | 'hasNextPage'>
    & { items: Array<(
      { __typename?: 'FormType' }
      & FormListItemFragment
    )> }
  )> }
);

export type GetFormQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetFormQuery = (
  { __typename?: 'QueryGraphType' }
  & { form?: Maybe<(
    { __typename?: 'FormType' }
    & { formTemplate: (
      { __typename?: 'FormTemplateType' }
      & Pick<FormTemplateType, 'activeFormTemplateId' | 'name'>
    ) }
    & FormFragment
  )> }
);

export type CreateFormMutationVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  form: CreateFormInputType;
  createTasks?: Maybe<Scalars['Boolean']>;
}>;


export type CreateFormMutation = (
  { __typename?: 'MutationGraphType' }
  & { createForm?: Maybe<(
    { __typename?: 'FormType' }
    & FormListItemFragment
  )> }
);

export type UpdateFormMutationVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  form: UpdateFormInputType;
  createTasks?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateFormMutation = (
  { __typename?: 'MutationGraphType' }
  & { updateForm?: Maybe<(
    { __typename?: 'FormType' }
    & Pick<FormType, 'id'>
    & FormFragment
  )> }
);

export type FormTemplateListItemFragment = (
  { __typename?: 'FormTemplateType' }
  & Pick<FormTemplateType, 'id' | 'name'>
);

export type FormTemplateByTagListItemFragment = (
  { __typename?: 'TaggedEntityType' }
  & Pick<TaggedEntityType, 'id' | 'name'>
);

export type FormListItemFragment = (
  { __typename?: 'FormType' }
  & Pick<FormType, 'id' | 'name' | 'createdDateTime' | 'isSubmitted'>
  & { createdByUser: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  ), filterFieldData: Array<(
    { __typename?: 'FormDataValueType' }
    & Pick<FormDataValueType, 'name' | 'value'>
  )> }
);

export type FormFragment = (
  { __typename?: 'FormType' }
  & Pick<FormType, 'id' | 'name' | 'templateId' | 'template' | 'parentId' | 'formDataJson' | 'createdDateTime' | 'isSubmitted' | 'isConfidential'>
  & { createdByUser: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  ) }
);

export type GetSystemFormTemplateQueryVariables = Exact<{
  id: Scalars['Guid'];
}>;


export type GetSystemFormTemplateQuery = (
  { __typename?: 'QueryGraphType' }
  & { systemFormTemplate?: Maybe<(
    { __typename?: 'FormTemplateType' }
    & Pick<FormTemplateType, 'id' | 'name' | 'template'>
  )> }
);

export type UpdateFormTemplateMutationVariables = Exact<{
  formTemplate: UpdateFormTemplateInputType;
  newFormTemplateId?: Maybe<Scalars['Guid']>;
}>;


export type UpdateFormTemplateMutation = (
  { __typename?: 'MutationGraphType' }
  & { updateFormTemplate?: Maybe<(
    { __typename?: 'FormTemplateType' }
    & Pick<FormTemplateType, 'id' | 'name' | 'template'>
  )> }
);

export type GetSystemReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSystemReportsQuery = (
  { __typename?: 'QueryGraphType' }
  & { systemReports?: Maybe<Array<(
    { __typename?: 'SystemReportType' }
    & ReportListItemFragment
  )>> }
);

export type GetSystemReportQueryVariables = Exact<{
  id: Scalars['Guid'];
}>;


export type GetSystemReportQuery = (
  { __typename?: 'QueryGraphType' }
  & { systemReport?: Maybe<(
    { __typename?: 'SystemReportType' }
    & ReportFragment
  )> }
);

export type CreateReportMutationVariables = Exact<{
  report: CreateReportInputType;
}>;


export type CreateReportMutation = (
  { __typename?: 'MutationGraphType' }
  & { createReport?: Maybe<(
    { __typename?: 'SystemReportType' }
    & ReportFragment
  )> }
);

export type UpdateReportMutationVariables = Exact<{
  report: UpdateReportInputType;
}>;


export type UpdateReportMutation = (
  { __typename?: 'MutationGraphType' }
  & { updateReport?: Maybe<(
    { __typename?: 'SystemReportType' }
    & ReportFragment
  )> }
);

export type ReportFragment = (
  { __typename?: 'SystemReportType' }
  & Pick<SystemReportType, 'id' | 'name' | 'isPublished' | 'reportingDatabase' | 'sqlQuery' | 'uiData'>
);

export type ReportListItemFragment = (
  { __typename?: 'SystemReportType' }
  & Pick<SystemReportType, 'name' | 'id' | 'isPublished'>
);

export type GetTenantWithFormTemplatesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTenantWithFormTemplatesQuery = (
  { __typename?: 'QueryGraphType' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantType' }
    & TenantWithFormTemplatesFragment
  )> }
);

export type GetSystemFormTemplatesQueryVariables = Exact<{
  filterProps?: Maybe<FormTemplateFilterPropsType>;
  paginationProps: FormTemplatePaginationPropsType;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetSystemFormTemplatesQuery = (
  { __typename?: 'QueryGraphType' }
  & { systemFormTemplates?: Maybe<(
    { __typename?: 'FormTemplatePageType' }
    & Pick<FormTemplatePageType, 'startCursor' | 'hasPreviousPage' | 'endCursor' | 'hasNextPage'>
    & { items: Array<(
      { __typename?: 'FormTemplateType' }
      & Pick<FormTemplateType, 'id' | 'name'>
    )> }
  )> }
);

export type AssignFormTemplateToTenantModulesMutationVariables = Exact<{
  formTemplateId: Scalars['ID'];
  tenantId: Scalars['Int'];
  modules: Array<Module> | Module;
}>;


export type AssignFormTemplateToTenantModulesMutation = (
  { __typename?: 'MutationGraphType' }
  & { assignFormTemplateToTenantModules?: Maybe<(
    { __typename?: 'FormTemplateType' }
    & TenantFormTemplateFragment
  )> }
);

export type TenantWithFormTemplatesFragment = (
  { __typename?: 'TenantType' }
  & Pick<TenantType, 'id'>
  & { modules: Array<(
    { __typename?: 'ModuleType' }
    & Pick<ModuleType, 'name' | 'value'>
  )>, formTemplates?: Maybe<Array<(
    { __typename?: 'FormTemplateType' }
    & TenantFormTemplateFragment
  )>> }
);

export type TenantFormTemplateFragment = (
  { __typename?: 'FormTemplateType' }
  & Pick<FormTemplateType, 'id'>
  & { modules: Array<(
    { __typename?: 'ModuleType' }
    & Pick<ModuleType, 'value'>
  )> }
);

export type GetTenantWithReportsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTenantWithReportsQuery = (
  { __typename?: 'QueryGraphType' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantType' }
    & TenantWithReportsFragment
  )> }
);

export type AssignReportToTenantModulesMutationVariables = Exact<{
  reportId: Scalars['ID'];
  tenantId: Scalars['Int'];
  modules: Array<Module> | Module;
}>;


export type AssignReportToTenantModulesMutation = (
  { __typename?: 'MutationGraphType' }
  & { assignReportToTenantModules?: Maybe<(
    { __typename?: 'TenantReportType' }
    & TenantReportWithModulesFragment
  )> }
);

export type TenantWithReportsFragment = (
  { __typename?: 'TenantType' }
  & Pick<TenantType, 'id'>
  & { modules: Array<(
    { __typename?: 'ModuleType' }
    & Pick<ModuleType, 'name' | 'value'>
  )>, reports?: Maybe<Array<(
    { __typename?: 'TenantReportType' }
    & TenantReportWithModulesFragment
  )>> }
);

export type TenantReportWithModulesFragment = (
  { __typename?: 'TenantReportType' }
  & Pick<TenantReportType, 'id'>
  & { modules: Array<(
    { __typename?: 'ModuleType' }
    & Pick<ModuleType, 'value'>
  )> }
);

export type GetTenantWithRolesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTenantWithRolesQuery = (
  { __typename?: 'QueryGraphType' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantType' }
    & TenantWithRolesFragment
  )> }
);

export type CreateRoleMutationVariables = Exact<{
  tenantId: Scalars['Int'];
  role: CreateRoleInputType;
}>;


export type CreateRoleMutation = (
  { __typename?: 'MutationGraphType' }
  & { createRole?: Maybe<(
    { __typename?: 'RoleType' }
    & RoleFragment
  )> }
);

export type UpdateRoleMutationVariables = Exact<{
  tenantId: Scalars['Int'];
  role: UpdateRoleInputType;
}>;


export type UpdateRoleMutation = (
  { __typename?: 'MutationGraphType' }
  & { updateRole?: Maybe<(
    { __typename?: 'RoleType' }
    & RoleFragment
  )> }
);

export type TenantWithRolesFragment = (
  { __typename?: 'TenantType' }
  & Pick<TenantType, 'id' | 'name'>
  & { roles?: Maybe<Array<(
    { __typename?: 'RoleType' }
    & RoleFragment
  )>> }
);

export type RoleFragment = (
  { __typename?: 'RoleType' }
  & Pick<RoleType, 'id' | 'name' | 'permissions'>
);

export type GetTenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantsQuery = (
  { __typename?: 'QueryGraphType' }
  & { tenants?: Maybe<Array<(
    { __typename?: 'TenantType' }
    & Pick<TenantType, 'id' | 'name'>
  )>> }
);

export type GetTenantQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTenantQuery = (
  { __typename?: 'QueryGraphType' }
  & { tenant?: Maybe<(
    { __typename?: 'TenantType' }
    & Pick<TenantType, 'id' | 'name' | 'status' | 'createdDateTime' | 'clientId' | 'domain'>
    & { modules: Array<(
      { __typename?: 'ModuleType' }
      & Pick<ModuleType, 'name'>
    )> }
  )> }
);

export type GetLibraryFolderQueryVariables = Exact<{
  folderId: Scalars['Guid'];
}>;


export type GetLibraryFolderQuery = (
  { __typename?: 'QueryGraphType' }
  & { libraryFolder?: Maybe<(
    { __typename?: 'LibraryFolderType' }
    & Pick<LibraryFolderType, 'id' | 'name' | 'parentFolderId' | 'downloadFileUrlTemplate'>
    & { folders: Array<(
      { __typename?: 'LibraryFolderType' }
      & LibraryFolderListItemFragment
    )>, files: Array<(
      { __typename?: 'LibraryFileType' }
      & LibraryFileListItemFragment
    )>, ancestorFolders: Array<(
      { __typename?: 'LibraryAncestorFolderType' }
      & Pick<LibraryAncestorFolderType, 'id' | 'name'>
    )> }
  )> }
);

export type GetLibraryFilesQueryVariables = Exact<{
  searchQuery: Scalars['String'];
}>;


export type GetLibraryFilesQuery = (
  { __typename?: 'QueryGraphType' }
  & { libraryFiles?: Maybe<(
    { __typename?: 'LibraryFilesType' }
    & Pick<LibraryFilesType, 'downloadFileUrlTemplate'>
    & { files: Array<(
      { __typename?: 'LibraryFileType' }
      & LibraryFileSearchResultFragment
    )> }
  )> }
);

export type CreateLibraryFolderMutationVariables = Exact<{
  parentFolderId: Scalars['Guid'];
  name: Scalars['String'];
}>;


export type CreateLibraryFolderMutation = (
  { __typename?: 'MutationGraphType' }
  & { createLibraryFolder?: Maybe<(
    { __typename?: 'LibraryFolderType' }
    & LibraryFolderListItemFragment
  )> }
);

export type CreateLibraryFilesMutationVariables = Exact<{
  folderId: Scalars['Guid'];
  fileNames: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateLibraryFilesMutation = (
  { __typename?: 'MutationGraphType' }
  & { createLibraryFiles?: Maybe<(
    { __typename?: 'CreateLibraryFilesResponseType' }
    & Pick<CreateLibraryFilesResponseType, 'uploadUrlTemplate'>
    & { files: Array<(
      { __typename?: 'LibraryFileType' }
      & LibraryFileListItemFragment
    )> }
  )> }
);

export type DeleteLibraryFolderMutationVariables = Exact<{
  folderId: Scalars['Guid'];
}>;


export type DeleteLibraryFolderMutation = (
  { __typename?: 'MutationGraphType' }
  & { deleteLibraryFolder?: Maybe<(
    { __typename?: 'LibraryFolderType' }
    & Pick<LibraryFolderType, 'id'>
  )> }
);

export type DeleteLibraryFileMutationVariables = Exact<{
  folderId: Scalars['Guid'];
  fileId: Scalars['Guid'];
}>;


export type DeleteLibraryFileMutation = (
  { __typename?: 'MutationGraphType' }
  & { deleteLibraryFile?: Maybe<(
    { __typename?: 'LibraryFileType' }
    & Pick<LibraryFileType, 'id' | 'folderId'>
  )> }
);

export type LibraryFolderListItemFragment = (
  { __typename?: 'LibraryFolderType' }
  & Pick<LibraryFolderType, 'id' | 'name' | 'createdDateTime'>
  & { createdByUser: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'name'>
  ) }
);

export type LibraryFileSearchResultFragment = (
  { __typename?: 'LibraryFileType' }
  & { folder: (
    { __typename?: 'LibraryFolderType' }
    & Pick<LibraryFolderType, 'id' | 'name'>
  ), ancestorFolders: Array<(
    { __typename?: 'LibraryAncestorFolderType' }
    & Pick<LibraryAncestorFolderType, 'id' | 'name'>
  )> }
  & LibraryFileListItemFragment
);

export type LibraryFileListItemFragment = (
  { __typename?: 'LibraryFileType' }
  & Pick<LibraryFileType, 'id' | 'name' | 'createdDateTime'>
  & { createdByUser: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'name'>
  ), tags: Array<(
    { __typename?: 'TagType' }
    & Pick<TagType, 'id' | 'value'>
  )> }
);

export type GetMyNotificationPreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyNotificationPreferencesQuery = (
  { __typename?: 'QueryGraphType' }
  & { myNotificationPreferences?: Maybe<Array<(
    { __typename?: 'UserNotificationPreferenceType' }
    & NotificationPreferenceFragment
  )>>, notificationTypes?: Maybe<Array<(
    { __typename?: 'NotificationTypeType' }
    & Pick<NotificationTypeType, 'value' | 'description' | 'defaultValue'>
  )>> }
);

export type UpdateMyNotificationPreferencesMutationVariables = Exact<{
  preferences: Array<UserNotificationPreferenceInputType> | UserNotificationPreferenceInputType;
}>;


export type UpdateMyNotificationPreferencesMutation = (
  { __typename?: 'MutationGraphType' }
  & { updateMyNotificationPreferences?: Maybe<Array<Maybe<(
    { __typename?: 'UserNotificationPreferenceType' }
    & NotificationPreferenceFragment
  )>>> }
);

export type NotificationPreferenceFragment = (
  { __typename?: 'UserNotificationPreferenceType' }
  & Pick<UserNotificationPreferenceType, 'notificationType' | 'isEnabled' | 'customData'>
);

export type GetMyReportsQueryVariables = Exact<{
  module: Module;
}>;


export type GetMyReportsQuery = (
  { __typename?: 'QueryGraphType' }
  & { myReports?: Maybe<Array<(
    { __typename?: 'ReportType' }
    & Pick<ReportType, 'id' | 'name'>
  )>> }
);

export type GetMyReportQueryVariables = Exact<{
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetMyReportQuery = (
  { __typename?: 'QueryGraphType' }
  & { myReport?: Maybe<(
    { __typename?: 'SystemReportType' }
    & Pick<SystemReportType, 'name' | 'uiData'>
    & { queryDefinition?: Maybe<(
      { __typename?: 'ReportQueryDefinitionType' }
      & { inputParameters: Array<(
        { __typename?: 'NameTypeType' }
        & Pick<NameTypeType, 'name' | 'type'>
      )>, resultColumns: Array<(
        { __typename?: 'NameTypeType' }
        & Pick<NameTypeType, 'name' | 'type'>
      )> }
    )> }
  )> }
);

export type GetFormTemplateQueryDefinitionQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetFormTemplateQueryDefinitionQuery = (
  { __typename?: 'QueryGraphType' }
  & { formTemplate?: Maybe<(
    { __typename?: 'FormTemplateType' }
    & { queryDefinition?: Maybe<(
      { __typename?: 'ReportQueryDefinitionType' }
      & { resultColumns: Array<(
        { __typename?: 'NameTypeType' }
        & Pick<NameTypeType, 'name' | 'type'>
      )> }
    )> }
  )> }
);

export type GetMySitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMySitesQuery = (
  { __typename?: 'QueryGraphType' }
  & { mySites?: Maybe<Array<(
    { __typename?: 'SiteType' }
    & Pick<SiteType, 'id' | 'name' | 'parentSiteId'>
  )>> }
);

export type UpdateSiteMutationVariables = Exact<{
  site: UpdateSiteInputType;
}>;


export type UpdateSiteMutation = (
  { __typename?: 'MutationGraphType' }
  & { updateSite?: Maybe<(
    { __typename?: 'SiteAdminType' }
    & SiteFragment
  )> }
);

export type SiteFragment = (
  { __typename?: 'SiteAdminType' }
  & Pick<SiteAdminType, 'id' | 'name' | 'parentSiteId'>
);

export type GetTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsQuery = (
  { __typename?: 'QueryGraphType' }
  & Pick<QueryGraphType, 'tags'>
);

export type CreateTagMutationVariables = Exact<{
  module?: Maybe<Module>;
  tag: CreateTagInputType;
}>;


export type CreateTagMutation = (
  { __typename?: 'MutationGraphType' }
  & { createTag?: Maybe<(
    { __typename?: 'TagType' }
    & Pick<TagType, 'id' | 'value'>
  )> }
);

export type DeleteTagMutationVariables = Exact<{
  tagId: Scalars['Guid'];
}>;


export type DeleteTagMutation = (
  { __typename?: 'MutationGraphType' }
  & { deleteTag?: Maybe<(
    { __typename?: 'TagType' }
    & Pick<TagType, 'id'>
  )> }
);

export type GetMyTasksQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  filterProps?: Maybe<TaskFilterPropsType>;
  paginationProps: TaskPaginationPropsType;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetMyTasksQuery = (
  { __typename?: 'QueryGraphType' }
  & { myTasks?: Maybe<(
    { __typename?: 'TaskPageType' }
    & Pick<TaskPageType, 'startCursor' | 'hasPreviousPage' | 'endCursor' | 'hasNextPage'>
    & { items: Array<(
      { __typename?: 'TaskType' }
      & TaskListItemFragment
    )> }
  )> }
);

export type GetMyTaskQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetMyTaskQuery = (
  { __typename?: 'QueryGraphType' }
  & Pick<QueryGraphType, 'downloadAttachmentUrlTemplate'>
  & { myTask?: Maybe<(
    { __typename?: 'TaskType' }
    & TaskFragment
  )> }
);

export type GetMyTaskReminderQueryVariables = Exact<{
  taskId: Scalars['Guid'];
}>;


export type GetMyTaskReminderQuery = (
  { __typename?: 'QueryGraphType' }
  & { myTaskReminder?: Maybe<(
    { __typename?: 'UserTaskReminderOverrideType' }
    & Pick<UserTaskReminderOverrideType, 'taskId' | 'reminderPeriodInHours'>
  )> }
);

export type SetMyTaskReminderMutationVariables = Exact<{
  taskId: Scalars['Guid'];
  reminderPeriodInHours?: Maybe<Scalars['Int']>;
}>;


export type SetMyTaskReminderMutation = (
  { __typename?: 'MutationGraphType' }
  & { setMyTaskReminder?: Maybe<(
    { __typename?: 'UserTaskReminderOverrideType' }
    & Pick<UserTaskReminderOverrideType, 'taskId' | 'reminderPeriodInHours'>
  )> }
);

export type CreateTaskMutationVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  task: CreateTaskInputType;
}>;


export type CreateTaskMutation = (
  { __typename?: 'MutationGraphType' }
  & { createTask?: Maybe<(
    { __typename?: 'TaskType' }
    & TaskListItemFragment
  )> }
);

export type CreateTaskNoteMutationVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  taskId: Scalars['Guid'];
  note: Scalars['String'];
}>;


export type CreateTaskNoteMutation = (
  { __typename?: 'MutationGraphType' }
  & { createTaskNote?: Maybe<(
    { __typename?: 'TaskNoteType' }
    & TaskNoteFragment
  )> }
);

export type UpdateMyTaskMutationVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  task: UpdateTaskInputType;
  note?: Maybe<Scalars['String']>;
}>;


export type UpdateMyTaskMutation = (
  { __typename?: 'MutationGraphType' }
  & { updateMyTask?: Maybe<(
    { __typename?: 'TaskType' }
    & Pick<TaskType, 'id'>
    & TaskFragment
  )> }
);

export type CloseMyTaskMutationVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  taskId: Scalars['Guid'];
  completedDateTime: Scalars['DateTimeOffset'];
}>;


export type CloseMyTaskMutation = (
  { __typename?: 'MutationGraphType' }
  & { closeMyTask?: Maybe<(
    { __typename?: 'TaskType' }
    & Pick<TaskType, 'id'>
    & TaskFragment
  )> }
);

export type TaskListItemFragment = (
  { __typename?: 'TaskType' }
  & Pick<TaskType, 'id' | 'name' | 'referenceNumber' | 'createdByFormTemplateName' | 'open' | 'completedDate' | 'dueDate'>
  & { assignedToUsers: Array<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  )>, assignedToTeams: Array<(
    { __typename?: 'TeamType' }
    & Pick<TeamType, 'id' | 'name'>
  )>, createdByUser: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  ) }
);

export type TaskFragment = (
  { __typename?: 'TaskType' }
  & Pick<TaskType, 'referenceNumber' | 'name' | 'description' | 'createdDateTime' | 'dueDate' | 'open' | 'completedDate' | 'createdByFormTemplateName'>
  & { createdByUser: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  ), assignedToUsers: Array<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  )>, assignedToTeams: Array<(
    { __typename?: 'TeamType' }
    & Pick<TeamType, 'id' | 'name'>
  )>, attachments: Array<(
    { __typename?: 'AttachmentType' }
    & Pick<AttachmentType, 'id' | 'blobStorageId' | 'fileName' | 'name'>
  )>, notes: Array<(
    { __typename?: 'TaskNoteType' }
    & TaskNoteFragment
  )> }
);

export type TaskNoteFragment = (
  { __typename?: 'TaskNoteType' }
  & Pick<TaskNoteType, 'note' | 'createdDateTime'>
  & { user: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  ) }
);

export type GetTeamsQueryVariables = Exact<{
  module: Module;
}>;


export type GetTeamsQuery = (
  { __typename?: 'QueryGraphType' }
  & { teams?: Maybe<Array<(
    { __typename?: 'TeamType' }
    & Pick<TeamType, 'id' | 'name'>
    & { users?: Maybe<Array<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
    )>> }
  )>> }
);

export type GetTeamWithUsersQueryVariables = Exact<{
  siteId: Scalars['Int'];
  module: Module;
  id: Scalars['Guid'];
}>;


export type GetTeamWithUsersQuery = (
  { __typename?: 'QueryGraphType' }
  & { team?: Maybe<(
    { __typename?: 'TeamType' }
    & Pick<TeamType, 'name'>
    & { users?: Maybe<Array<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
    )>> }
  )>, users?: Maybe<Array<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'name'>
  )>> }
);

export type GetMyTenantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyTenantQuery = (
  { __typename?: 'QueryGraphType' }
  & { myTenant?: Maybe<(
    { __typename?: 'TenantType' }
    & Pick<TenantType, 'name' | 'domain' | 'uiData'>
    & { modules: Array<(
      { __typename?: 'ModuleType' }
      & Pick<ModuleType, 'id' | 'name' | 'value'>
    )> }
  )> }
);

export const TenantReportWithUsers = gql`
    fragment TenantReportWithUsers on TenantReportType {
  id
  name
  users {
    id
  }
}
    `;
export const SiteListItem = gql`
    fragment SiteListItem on SiteAdminType {
  id
  name
  externalId
  parentSiteId
}
    `;
export const RoleListItem = gql`
    fragment RoleListItem on RoleType {
  id
  name
}
    `;
export const RegisteredUser = gql`
    fragment RegisteredUser on UserAdminType {
  id
  name
  email
  role {
    id
    name
  }
  sites {
    id
    name
  }
}
    `;
export const PendingInvitation = gql`
    fragment PendingInvitation on InvitationType {
  email
  createdDateTime
  sites {
    id
    name
  }
}
    `;
export const User = gql`
    fragment User on UserAdminType {
  id
  firstName
  lastName
  email
  modules
  role {
    id
    name
    permissions
  }
  sites {
    id
    name
  }
}
    `;
export const Attachment = gql`
    fragment Attachment on AttachmentType {
  id
  blobStorageId
  fileName
  name
  createdDateTime
  createdByUser {
    name
  }
  parentId
  parentType
}
    `;
export const FileType = gql`
    fragment FileType on FileTypeType {
  id
  name
  formTemplate {
    id
    template
  }
}
    `;
export const FileListItem = gql`
    fragment FileListItem on FileType {
  name
  createdDateTime
  createdByUser {
    name
  }
  id
}
    `;
export const FileNote = gql`
    fragment FileNote on FileNoteType {
  note
  createdDateTime
  user {
    name
  }
}
    `;
export const File = gql`
    fragment File on FileType {
  name
  createdByUser {
    id
    name
  }
  createdDateTime
  formId
  form {
    id
    template
    formDataJson
  }
  fileTypeId
  connectedTeams {
    id
    name
  }
  connectedUsers {
    id
    name
  }
  attachments {
    id
    blobStorageId
    fileName
    name
    createdDateTime
    createdByUser {
      name
    }
  }
  fileNotes {
    ...FileNote
  }
  myForms {
    id
    name
    createdDateTime
  }
  myTasks {
    id
    referenceNumber
    name
    description
    dueDate
    open
    assignedToUsers {
      name
    }
    assignedToTeams {
      name
    }
  }
}
    ${FileNote}`;
export const FormTemplateListItem = gql`
    fragment FormTemplateListItem on FormTemplateType {
  id
  name
}
    `;
export const FormTemplateByTagListItem = gql`
    fragment FormTemplateByTagListItem on TaggedEntityType {
  id
  name
}
    `;
export const FormListItem = gql`
    fragment FormListItem on FormType {
  id
  name
  createdByUser {
    id
    name
  }
  createdDateTime
  filterFieldData {
    name
    value
  }
  isSubmitted
}
    `;
export const Form = gql`
    fragment Form on FormType {
  id
  name
  templateId
  template
  createdByUser {
    id
    name
  }
  parentId
  formDataJson
  createdDateTime
  isSubmitted
  isConfidential
}
    `;
export const Report = gql`
    fragment Report on SystemReportType {
  id
  name
  isPublished
  reportingDatabase
  sqlQuery
  uiData
}
    `;
export const ReportListItem = gql`
    fragment ReportListItem on SystemReportType {
  name
  id
  isPublished
}
    `;
export const TenantFormTemplate = gql`
    fragment TenantFormTemplate on FormTemplateType {
  id
  modules {
    value
  }
}
    `;
export const TenantWithFormTemplates = gql`
    fragment TenantWithFormTemplates on TenantType {
  id
  modules {
    name
    value
  }
  formTemplates {
    ...TenantFormTemplate
  }
}
    ${TenantFormTemplate}`;
export const TenantReportWithModules = gql`
    fragment TenantReportWithModules on TenantReportType {
  id
  modules {
    value
  }
}
    `;
export const TenantWithReports = gql`
    fragment TenantWithReports on TenantType {
  id
  modules {
    name
    value
  }
  reports {
    ...TenantReportWithModules
  }
}
    ${TenantReportWithModules}`;
export const Role = gql`
    fragment Role on RoleType {
  id
  name
  permissions
}
    `;
export const TenantWithRoles = gql`
    fragment TenantWithRoles on TenantType {
  id
  name
  roles {
    ...Role
  }
}
    ${Role}`;
export const LibraryFolderListItem = gql`
    fragment LibraryFolderListItem on LibraryFolderType {
  id
  name
  createdDateTime
  createdByUser {
    name
  }
}
    `;
export const LibraryFileListItem = gql`
    fragment LibraryFileListItem on LibraryFileType {
  id
  name
  createdDateTime
  createdByUser {
    name
  }
  tags {
    id
    value
  }
}
    `;
export const LibraryFileSearchResult = gql`
    fragment LibraryFileSearchResult on LibraryFileType {
  ...LibraryFileListItem
  folder {
    id
    name
  }
  ancestorFolders {
    id
    name
  }
}
    ${LibraryFileListItem}`;
export const NotificationPreference = gql`
    fragment NotificationPreference on UserNotificationPreferenceType {
  notificationType
  isEnabled
  customData
}
    `;
export const Site = gql`
    fragment Site on SiteAdminType {
  id
  name
  parentSiteId
}
    `;
export const TaskListItem = gql`
    fragment TaskListItem on TaskType {
  id
  name
  referenceNumber
  assignedToUsers {
    id
    name
  }
  assignedToTeams {
    id
    name
  }
  createdByFormTemplateName
  open
  completedDate
  dueDate
  createdByUser {
    id
    name
  }
}
    `;
export const TaskNote = gql`
    fragment TaskNote on TaskNoteType {
  note
  createdDateTime
  user {
    id
    name
  }
}
    `;
export const Task = gql`
    fragment Task on TaskType {
  referenceNumber
  name
  description
  createdByUser {
    id
    name
  }
  createdDateTime
  dueDate
  open
  completedDate
  assignedToUsers {
    id
    name
  }
  assignedToTeams {
    id
    name
  }
  attachments {
    id
    blobStorageId
    fileName
    name
  }
  notes {
    ...TaskNote
  }
  createdByFormTemplateName
}
    ${TaskNote}`;
export const GetTenantReportsDocument = gql`
    query GetTenantReports {
  tenantReports {
    id
    name
    users {
      name
    }
  }
}
    `;
export const GetTenantReportWithUsersDocument = gql`
    query GetTenantReportWithUsers($id: Guid!) {
  tenantReport(id: $id) {
    ...TenantReportWithUsers
  }
  usersAdmin {
    id
    name
    role {
      permissions
    }
  }
}
    ${TenantReportWithUsers}`;
export const UpdateReportUsersDocument = gql`
    mutation UpdateReportUsers($reportId: Guid!, $userIds: [Guid!]!) {
  updateReportUsers(reportId: $reportId, userIds: $userIds) {
    ...TenantReportWithUsers
  }
}
    ${TenantReportWithUsers}`;
export const GetFormTemplateRulesDocument = gql`
    query GetFormTemplateRules($siteId: Int!, $module: Module!, $id: Guid!) {
  formTemplate(siteId: $siteId, module: $module, id: $id) {
    name
    template
    rules {
      id
      fieldId
      name
      isActive
      eventType
      expectedValueJson
      actionJson
    }
  }
}
    `;
export const GetSitesAdminDocument = gql`
    query GetSitesAdmin {
  sitesAdmin {
    ...SiteListItem
  }
}
    ${SiteListItem}`;
export const CreateSiteDocument = gql`
    mutation CreateSite($parentSiteId: Int!, $site: CreateSiteInputType!) {
  createSite(parentSiteId: $parentSiteId, site: $site) {
    ...SiteListItem
  }
}
    ${SiteListItem}`;
export const GetRolesDocument = gql`
    query GetRoles {
  roles {
    ...RoleListItem
  }
}
    ${RoleListItem}`;
export const GetUsersAndTeamsDocument = gql`
    query GetUsersAndTeams($siteId: Int!, $module: Module!) {
  users(siteId: $siteId, module: $module) {
    id
    name
  }
  teams(module: $module) {
    id
    name
    users {
      id
    }
  }
}
    `;
export const GetUsersAdminDocument = gql`
    query GetUsersAdmin {
  usersAdmin {
    ...RegisteredUser
  }
  pendingInvitations {
    ...PendingInvitation
  }
}
    ${RegisteredUser}
${PendingInvitation}`;
export const GetUserAdminDocument = gql`
    query GetUserAdmin($id: Guid!) {
  userAdmin(id: $id) {
    ...User
  }
}
    ${User}`;
export const CreateInvitationDocument = gql`
    mutation CreateInvitation($invitation: CreateInvitationInputType!) {
  createInvitation(invitation: $invitation) {
    ...PendingInvitation
  }
}
    ${PendingInvitation}`;
export const UpdateUserDocument = gql`
    mutation UpdateUser($user: UpdateUserInputType!) {
  updateUser(user: $user) {
    ...User
  }
}
    ${User}`;
export const GetTeamsAndUsersDocument = gql`
    query GetTeamsAndUsers($siteId: Int!, $module: Module!) {
  teams(module: $module) {
    id
    name
  }
  users(siteId: $siteId, module: $module) {
    id
    name
  }
}
    `;
export const CreateAttachmentsDocument = gql`
    mutation CreateAttachments($module: Module!, $parentId: Guid!, $parentType: ParentType!, $fileNames: [String!]!) {
  createAttachments(module: $module, parentId: $parentId, parentType: $parentType, fileNames: $fileNames) {
    attachments {
      ...Attachment
    }
    uploadUrlTemplate
  }
}
    ${Attachment}`;
export const UpdateAttachmentDocument = gql`
    mutation UpdateAttachment($module: Module!, $id: Guid!, $name: String!) {
  updateAttachment(module: $module, attachment: {id: $id, name: $name}) {
    ...Attachment
  }
}
    ${Attachment}`;
export const DeleteAttachmentDocument = gql`
    mutation DeleteAttachment($module: Module!, $id: Guid!) {
  deleteAttachment(module: $module, id: $id) {
    id
  }
}
    `;
export const GetDownloadAttachmentUrlTemplateDocument = gql`
    query GetDownloadAttachmentUrlTemplate {
  downloadAttachmentUrlTemplate
}
    `;
export const GetAttachmentDetailDocument = gql`
    query GetAttachmentDetail($module: Module!, $id: Guid!) {
  attachment(module: $module, id: $id) {
    ...Attachment
  }
  downloadAttachmentUrlTemplate
}
    ${Attachment}`;
export const GetAttachmentUrlDocument = gql`
    query GetAttachmentUrl($module: Module!, $id: Guid!) {
  attachment(module: $module, id: $id) {
    blobStorageId
    fileName
    name
  }
  downloadAttachmentUrlTemplate
}
    `;
export const GetBuildNumberDocument = gql`
    query GetBuildNumber {
  buildNumber
}
    `;
export const GetFileTypesDocument = gql`
    query GetFileTypes($module: Module!) {
  fileTypes(module: $module) {
    id
    name
    uiData
  }
}
    `;
export const GetFileTypeDocument = gql`
    query GetFileType($module: Module!, $id: Guid!) {
  fileType(module: $module, id: $id) {
    ...FileType
  }
}
    ${FileType}`;
export const GetMyFilesDocument = gql`
    query GetMyFiles($siteId: Int!, $module: Module!, $fileTypeId: Guid!, $filterProps: FileFilterPropsType, $paginationProps: FilePaginationPropsType!, $before: String, $after: String) {
  fileType(module: $module, id: $fileTypeId) {
    ...FileType
  }
  myFiles(siteId: $siteId, module: $module, fileTypeId: $fileTypeId, filterProps: $filterProps, paginationProps: $paginationProps, before: $before, after: $after) {
    items {
      ...FileListItem
    }
    startCursor
    hasPreviousPage
    endCursor
    hasNextPage
  }
}
    ${FileType}
${FileListItem}`;
export const GetMyFileDocument = gql`
    query GetMyFile($siteId: Int!, $module: Module!, $id: Guid!) {
  myFile(siteId: $siteId, module: $module, id: $id) {
    ...File
  }
}
    ${File}`;
export const CreateFileDocument = gql`
    mutation CreateFile($siteId: Int!, $module: Module!, $fileTypeId: Guid!, $form: CreateFormInputType!) {
  createFile(siteId: $siteId, module: $module, fileTypeId: $fileTypeId, form: $form) {
    ...FileListItem
  }
}
    ${FileListItem}`;
export const CreateFileNoteDocument = gql`
    mutation CreateFileNote($siteId: Int!, $module: Module!, $fileId: Guid!, $note: String!) {
  createFileNote(siteId: $siteId, module: $module, fileId: $fileId, note: $note) {
    ...FileNote
  }
}
    ${FileNote}`;
export const UpdateFileDocument = gql`
    mutation UpdateFile($siteId: Int!, $module: Module!, $fileId: Guid!, $form: UpdateFormInputType!) {
  updateFile(siteId: $siteId, module: $module, fileId: $fileId, form: $form) {
    ...File
  }
}
    ${File}`;
export const GetFormTemplatesDocument = gql`
    query GetFormTemplates($siteId: Int!, $module: Module!, $filterProps: FormTemplateFilterPropsType, $paginationProps: FormTemplatePaginationPropsType!, $before: String, $after: String) {
  formTemplates(siteId: $siteId, module: $module, filterProps: $filterProps, paginationProps: $paginationProps, before: $before, after: $after) {
    items {
      ...FormTemplateListItem
    }
    startCursor
    hasPreviousPage
    endCursor
    hasNextPage
  }
}
    ${FormTemplateListItem}`;
export const GetFormTemplatesByTagDocument = gql`
    query GetFormTemplatesByTag($module: Module!, $tagValue: String!) {
  taggedEntities(module: $module, parentType: FORM_TEMPLATE, tagValue: $tagValue) {
    ...FormTemplateByTagListItem
  }
}
    ${FormTemplateByTagListItem}`;
export const GetFormTemplateDocument = gql`
    query GetFormTemplate($siteId: Int!, $module: Module!, $id: Guid!) {
  formTemplate(siteId: $siteId, module: $module, id: $id) {
    id
    name
    template
    activeFormTemplateId
  }
}
    `;
export const GetFormTemplateWithFilterFieldsDocument = gql`
    query GetFormTemplateWithFilterFields($siteId: Int!, $module: Module!, $id: Guid!) {
  formTemplate(siteId: $siteId, module: $module, id: $id) {
    id
    name
    canSubmit
    filterFields {
      fieldId
      label
      options {
        name
        value
      }
    }
  }
}
    `;
export const GetFormTemplateAndInstancesDocument = gql`
    query GetFormTemplateAndInstances($siteId: Int!, $module: Module!, $formTemplateId: Guid!, $filterProps: FormFilterPropsType, $paginationProps: FormPaginationPropsType!, $before: String, $after: String) {
  forms(siteId: $siteId, module: $module, formTemplateId: $formTemplateId, filterProps: $filterProps, paginationProps: $paginationProps, before: $before, after: $after) {
    items {
      ...Form
    }
    startCursor
    hasPreviousPage
    endCursor
    hasNextPage
  }
  formTemplate(siteId: $siteId, module: $module, id: $formTemplateId) {
    name
    template
  }
}
    ${Form}`;
export const GetFormsDocument = gql`
    query GetForms($siteId: Int!, $module: Module!, $formTemplateId: Guid!, $filterProps: FormFilterPropsType, $paginationProps: FormPaginationPropsType!, $before: String, $after: String) {
  forms(siteId: $siteId, module: $module, formTemplateId: $formTemplateId, filterProps: $filterProps, paginationProps: $paginationProps, before: $before, after: $after) {
    items {
      ...FormListItem
    }
    startCursor
    hasPreviousPage
    endCursor
    hasNextPage
  }
}
    ${FormListItem}`;
export const GetFormDocument = gql`
    query GetForm($siteId: Int!, $module: Module!, $id: Guid!) {
  form(siteId: $siteId, module: $module, id: $id) {
    ...Form
    formTemplate {
      activeFormTemplateId
      name
    }
  }
}
    ${Form}`;
export const CreateFormDocument = gql`
    mutation CreateForm($siteId: Int!, $module: Module!, $form: CreateFormInputType!, $createTasks: Boolean) {
  createForm(siteId: $siteId, module: $module, form: $form, createTasks: $createTasks) {
    ...FormListItem
  }
}
    ${FormListItem}`;
export const UpdateFormDocument = gql`
    mutation UpdateForm($siteId: Int!, $module: Module!, $form: UpdateFormInputType!, $createTasks: Boolean) {
  updateForm(siteId: $siteId, module: $module, form: $form, createTasks: $createTasks) {
    id
    ...Form
  }
}
    ${Form}`;
export const GetSystemFormTemplateDocument = gql`
    query GetSystemFormTemplate($id: Guid!) {
  systemFormTemplate(id: $id) {
    id
    name
    template
  }
}
    `;
export const UpdateFormTemplateDocument = gql`
    mutation UpdateFormTemplate($formTemplate: UpdateFormTemplateInputType!, $newFormTemplateId: Guid) {
  updateFormTemplate(formTemplate: $formTemplate, newFormTemplateId: $newFormTemplateId) {
    id
    name
    template
  }
}
    `;
export const GetSystemReportsDocument = gql`
    query GetSystemReports {
  systemReports {
    ...ReportListItem
  }
}
    ${ReportListItem}`;
export const GetSystemReportDocument = gql`
    query GetSystemReport($id: Guid!) {
  systemReport(id: $id) {
    ...Report
  }
}
    ${Report}`;
export const CreateReportDocument = gql`
    mutation CreateReport($report: CreateReportInputType!) {
  createReport(report: $report) {
    ...Report
  }
}
    ${Report}`;
export const UpdateReportDocument = gql`
    mutation UpdateReport($report: UpdateReportInputType!) {
  updateReport(report: $report) {
    ...Report
  }
}
    ${Report}`;
export const GetTenantWithFormTemplatesDocument = gql`
    query GetTenantWithFormTemplates($id: Int!) {
  tenant(id: $id) {
    ...TenantWithFormTemplates
  }
}
    ${TenantWithFormTemplates}`;
export const GetSystemFormTemplatesDocument = gql`
    query GetSystemFormTemplates($filterProps: FormTemplateFilterPropsType, $paginationProps: FormTemplatePaginationPropsType!, $before: String, $after: String) {
  systemFormTemplates(filterProps: $filterProps, paginationProps: $paginationProps, before: $before, after: $after) {
    items {
      id
      name
    }
    startCursor
    hasPreviousPage
    endCursor
    hasNextPage
  }
}
    `;
export const AssignFormTemplateToTenantModulesDocument = gql`
    mutation AssignFormTemplateToTenantModules($formTemplateId: ID!, $tenantId: Int!, $modules: [Module!]!) {
  assignFormTemplateToTenantModules(formTemplateId: $formTemplateId, tenantId: $tenantId, modules: $modules) {
    ...TenantFormTemplate
  }
}
    ${TenantFormTemplate}`;
export const GetTenantWithReportsDocument = gql`
    query GetTenantWithReports($id: Int!) {
  tenant(id: $id) {
    ...TenantWithReports
  }
}
    ${TenantWithReports}`;
export const AssignReportToTenantModulesDocument = gql`
    mutation AssignReportToTenantModules($reportId: ID!, $tenantId: Int!, $modules: [Module!]!) {
  assignReportToTenantModules(reportId: $reportId, tenantId: $tenantId, modules: $modules) {
    ...TenantReportWithModules
  }
}
    ${TenantReportWithModules}`;
export const GetTenantWithRolesDocument = gql`
    query GetTenantWithRoles($id: Int!) {
  tenant(id: $id) {
    ...TenantWithRoles
  }
}
    ${TenantWithRoles}`;
export const CreateRoleDocument = gql`
    mutation CreateRole($tenantId: Int!, $role: CreateRoleInputType!) {
  createRole(tenantId: $tenantId, role: $role) {
    ...Role
  }
}
    ${Role}`;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($tenantId: Int!, $role: UpdateRoleInputType!) {
  updateRole(tenantId: $tenantId, role: $role) {
    ...Role
  }
}
    ${Role}`;
export const GetTenantsDocument = gql`
    query GetTenants {
  tenants {
    id
    name
  }
}
    `;
export const GetTenantDocument = gql`
    query GetTenant($id: Int!) {
  tenant(id: $id) {
    id
    name
    modules {
      name
    }
    status
    createdDateTime
    clientId
    domain
  }
}
    `;
export const GetLibraryFolderDocument = gql`
    query GetLibraryFolder($folderId: Guid!) {
  libraryFolder(folderId: $folderId) {
    id
    name
    folders {
      ...LibraryFolderListItem
    }
    files {
      ...LibraryFileListItem
    }
    parentFolderId
    ancestorFolders {
      id
      name
    }
    downloadFileUrlTemplate
  }
}
    ${LibraryFolderListItem}
${LibraryFileListItem}`;
export const GetLibraryFilesDocument = gql`
    query GetLibraryFiles($searchQuery: String!) {
  libraryFiles(searchQuery: $searchQuery) {
    files {
      ...LibraryFileSearchResult
    }
    downloadFileUrlTemplate
  }
}
    ${LibraryFileSearchResult}`;
export const CreateLibraryFolderDocument = gql`
    mutation CreateLibraryFolder($parentFolderId: Guid!, $name: String!) {
  createLibraryFolder(parentFolderId: $parentFolderId, name: $name) {
    ...LibraryFolderListItem
  }
}
    ${LibraryFolderListItem}`;
export const CreateLibraryFilesDocument = gql`
    mutation CreateLibraryFiles($folderId: Guid!, $fileNames: [String!]!) {
  createLibraryFiles(folderId: $folderId, fileNames: $fileNames) {
    files {
      ...LibraryFileListItem
    }
    uploadUrlTemplate
  }
}
    ${LibraryFileListItem}`;
export const DeleteLibraryFolderDocument = gql`
    mutation DeleteLibraryFolder($folderId: Guid!) {
  deleteLibraryFolder(folderId: $folderId) {
    id
  }
}
    `;
export const DeleteLibraryFileDocument = gql`
    mutation DeleteLibraryFile($folderId: Guid!, $fileId: Guid!) {
  deleteLibraryFile(folderId: $folderId, fileId: $fileId) {
    id
    folderId
  }
}
    `;
export const GetMyNotificationPreferencesDocument = gql`
    query GetMyNotificationPreferences {
  myNotificationPreferences {
    ...NotificationPreference
  }
  notificationTypes {
    value
    description
    defaultValue
  }
}
    ${NotificationPreference}`;
export const UpdateMyNotificationPreferencesDocument = gql`
    mutation UpdateMyNotificationPreferences($preferences: [UserNotificationPreferenceInputType!]!) {
  updateMyNotificationPreferences(preferences: $preferences) {
    ...NotificationPreference
  }
}
    ${NotificationPreference}`;
export const GetMyReportsDocument = gql`
    query GetMyReports($module: Module!) {
  myReports(module: $module) {
    id
    name
  }
}
    `;
export const GetMyReportDocument = gql`
    query GetMyReport($module: Module!, $id: Guid!) {
  myReport(module: $module, id: $id) {
    name
    uiData
    queryDefinition {
      inputParameters {
        name
        type
      }
      resultColumns {
        name
        type
      }
    }
  }
}
    `;
export const GetFormTemplateQueryDefinitionDocument = gql`
    query GetFormTemplateQueryDefinition($siteId: Int!, $module: Module!, $id: Guid!) {
  formTemplate(siteId: $siteId, module: $module, id: $id) {
    queryDefinition {
      resultColumns {
        name
        type
      }
    }
  }
}
    `;
export const GetMySitesDocument = gql`
    query GetMySites {
  mySites {
    id
    name
    parentSiteId
  }
}
    `;
export const UpdateSiteDocument = gql`
    mutation UpdateSite($site: UpdateSiteInputType!) {
  updateSite(site: $site) {
    ...Site
  }
}
    ${Site}`;
export const GetTagsDocument = gql`
    query GetTags {
  tags
}
    `;
export const CreateTagDocument = gql`
    mutation CreateTag($module: Module, $tag: CreateTagInputType!) {
  createTag(module: $module, tag: $tag) {
    id
    value
  }
}
    `;
export const DeleteTagDocument = gql`
    mutation DeleteTag($tagId: Guid!) {
  deleteTag(tagId: $tagId) {
    id
  }
}
    `;
export const GetMyTasksDocument = gql`
    query GetMyTasks($siteId: Int!, $module: Module!, $filterProps: TaskFilterPropsType, $paginationProps: TaskPaginationPropsType!, $before: String, $after: String) {
  myTasks(siteId: $siteId, module: $module, filterProps: $filterProps, paginationProps: $paginationProps, before: $before, after: $after) {
    items {
      ...TaskListItem
    }
    startCursor
    hasPreviousPage
    endCursor
    hasNextPage
  }
}
    ${TaskListItem}`;
export const GetMyTaskDocument = gql`
    query GetMyTask($siteId: Int!, $module: Module!, $id: Guid!) {
  myTask(siteId: $siteId, module: $module, id: $id) {
    ...Task
  }
  downloadAttachmentUrlTemplate
}
    ${Task}`;
export const GetMyTaskReminderDocument = gql`
    query GetMyTaskReminder($taskId: Guid!) {
  myTaskReminder(taskId: $taskId) {
    taskId
    reminderPeriodInHours
  }
}
    `;
export const SetMyTaskReminderDocument = gql`
    mutation SetMyTaskReminder($taskId: Guid!, $reminderPeriodInHours: Int) {
  setMyTaskReminder(taskId: $taskId, reminderPeriodInHours: $reminderPeriodInHours) {
    taskId
    reminderPeriodInHours
  }
}
    `;
export const CreateTaskDocument = gql`
    mutation CreateTask($siteId: Int!, $module: Module!, $task: CreateTaskInputType!) {
  createTask(siteId: $siteId, module: $module, task: $task) {
    ...TaskListItem
  }
}
    ${TaskListItem}`;
export const CreateTaskNoteDocument = gql`
    mutation CreateTaskNote($siteId: Int!, $module: Module!, $taskId: Guid!, $note: String!) {
  createTaskNote(siteId: $siteId, module: $module, taskId: $taskId, note: $note) {
    ...TaskNote
  }
}
    ${TaskNote}`;
export const UpdateMyTaskDocument = gql`
    mutation UpdateMyTask($siteId: Int!, $module: Module!, $task: UpdateTaskInputType!, $note: String) {
  updateMyTask(siteId: $siteId, module: $module, task: $task, note: $note) {
    id
    ...Task
  }
}
    ${Task}`;
export const CloseMyTaskDocument = gql`
    mutation CloseMyTask($siteId: Int!, $module: Module!, $taskId: Guid!, $completedDateTime: DateTimeOffset!) {
  closeMyTask(siteId: $siteId, module: $module, taskId: $taskId, completedDateTime: $completedDateTime) {
    id
    ...Task
  }
}
    ${Task}`;
export const GetTeamsDocument = gql`
    query GetTeams($module: Module!) {
  teams(module: $module) {
    id
    name
    users {
      id
    }
  }
}
    `;
export const GetTeamWithUsersDocument = gql`
    query GetTeamWithUsers($siteId: Int!, $module: Module!, $id: Guid!) {
  team(module: $module, id: $id) {
    name
    users {
      id
    }
  }
  users(siteId: $siteId, module: $module) {
    id
    name
  }
}
    `;
export const GetMyTenantDocument = gql`
    query GetMyTenant {
  myTenant {
    name
    domain
    uiData
    modules {
      id
      name
      value
    }
  }
}
    `;


