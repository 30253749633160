/* eslint-disable import/prefer-default-export */
import { isBefore } from 'date-fns';
import { IAssignee } from 'formly/components/Assignees';
import { COLORS } from 'themes/colors';
import { TaskFragment, TaskListItemFragment } from 'tillr-graphql';
import { formatAsFriendlyDate } from 'utils';

export function formatTaskAssignees(task: TaskFragment) {
  const assignees: IAssignee[] = task.assignedToUsers
    .map((x) => ({ id: x.id, name: x.name, type: 'User' } as IAssignee))
    .concat(
      task.assignedToTeams.map((x) => ({ id: x.id, name: x.name, type: 'Team' } as IAssignee)),
    );

  return { ...task, assignees };
}

export function getTaskStatusFeatures(task: TaskListItemFragment): {
  status: string;
  label: string;
  color: string;
} {
  const dueDate = new Date(task.dueDate);
  const formattedDueDate = formatAsFriendlyDate(dueDate);

  if (task.open) {
    if (isBefore(new Date(), dueDate)) {
      return { status: 'Open', label: `Due: ${formattedDueDate}`, color: COLORS.TASKS.OPEN };
    }
    return {
      status: 'Overdue',
      label: `Overdue: ${formattedDueDate}`,
      color: COLORS.TASKS.OVERDUE,
    };
  }

  if (task.completedDate) {
    const completedDate = new Date(task.completedDate);
    const formattedCompletedDate = formatAsFriendlyDate(completedDate);
    return {
      status: 'Completed',
      label: `Completed: ${formattedCompletedDate}\n(was due: ${formattedDueDate})`,
      color: isBefore(completedDate, dueDate)
        ? COLORS.TASKS.COMPLETED_ON_TIME
        : COLORS.TASKS.COMPLETED_LATE,
    };
  }

  // Shouldn't happen, but possible, so be sure we don't crash
  return {
    status: 'Closed',
    label: `(was due: ${formattedDueDate})`,
    color: COLORS.TASKS.COMPLETED_ON_TIME,
  };
}
