import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { EmptyMessage } from 'components/Shared/EmptyMessage';
import { UserAvatar } from 'components/Shared/UserAvatar';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IAppTheme } from 'themes/useGetTheme';
import { TaskListItemFragment } from 'tillr-graphql';
import { AssigneesSummary } from './AssigneesSummary';
import { QuickViewButton } from './QuickViewButton';
import { TaskStatus } from './TaskStatus';

const useStyles = makeStyles((theme: IAppTheme) => ({
  automated: {
    backgroundColor: theme.automatedTasks?.defaultColor,
  },
}));

export function ResultsTable(props: { tasks: TaskListItemFragment[] }) {
  const classes = useStyles();
  const { tasks } = props;
  const history = useHistory();

  if (!tasks || !tasks.length) {
    return <EmptyMessage />;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const { taskId } = event.currentTarget.dataset;
    if (taskId) {
      history.push(`tasks/${taskId}`);
    }
  };

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <Hidden smDown>
                <TableCell>Ref</TableCell>
              </Hidden>
              <TableCell>Assignee(s)</TableCell>
              <Hidden smDown>
                <TableCell>Creator</TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell>Status</TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell align="right">Actions</TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((x) => (
              <TableRow key={x.id} hover>
                <TableCell className="user-clickable" data-task-id={x.id} onClick={handleClick}>
                  <div className="like-list-item-avatar">
                    <Hidden smDown>
                      <Avatar className={x.createdByFormTemplateName ? classes.automated : ''}>
                        <AssignmentTurnedInIcon />
                      </Avatar>
                    </Hidden>
                    <div className="like-list-item-avatar__text">
                      <Typography variant="body1" display="block">
                        {x.name}
                      </Typography>
                      {x.createdByFormTemplateName && (
                        <Typography variant="body2" display="block">
                          {x.createdByFormTemplateName}
                        </Typography>
                      )}
                    </div>
                  </div>
                </TableCell>
                <Hidden smDown>
                  <TableCell className="user-clickable" data-task-id={x.id} onClick={handleClick}>
                    {x.referenceNumber}
                  </TableCell>
                </Hidden>
                <TableCell className="user-clickable" data-task-id={x.id} onClick={handleClick}>
                  <AssigneesSummary users={x.assignedToUsers} teams={x.assignedToTeams} />
                </TableCell>
                <Hidden smDown>
                  <TableCell>
                    <UserAvatar name={x.createdByUser.name} id={x.createdByUser.id} />
                  </TableCell>
                </Hidden>
                <Hidden smDown>
                  <TableCell>
                    <TaskStatus task={x} />
                  </TableCell>
                </Hidden>
                <Hidden smDown>
                  <TableCell align="right">
                    <QuickViewButton taskId={x.id} />
                  </TableCell>
                </Hidden>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
