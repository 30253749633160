import { User } from 'oidc-client';
import { Module } from 'tillr-graphql';

interface ITaskAsignee {
  id: string;
  name: string;
}

export class UserProfile {
  readonly name: string;

  readonly authHeaderValue: string;

  readonly modules: Module[];

  readonly permissions: string[];

  readonly siteIds: number[];

  readonly tenantName: string;

  readonly getAvatarName: () => string;

  readonly getUserId: () => string;

  readonly userId: string;

  readonly tenantId: string;

  readonly expired: () => boolean;

  constructor(private user: User) {
    const { profile } = user;

    this.name = `${profile.given_name} ${profile.family_name}`;

    this.authHeaderValue = `${user.token_type} ${user.access_token}`;

    let moduleIds = this.getClaimValueAsArray('moduleId').map((x) => Number(x));
    if (moduleIds.some((x) => x !== 0)) {
      // We have actual modules speficied, so ignore the default "TillrPlatform" module
      moduleIds = moduleIds.filter((x) => x > 0);
    }
    this.modules = moduleIds.map((x) => Object.values(Module)[x]);

    this.permissions = this.getClaimValueAsArray('permission');

    this.siteIds = this.getClaimValueAsArray('siteId').map((x) => Number(x));

    this.tenantName = profile.tenantName;

    [this.tenantId] = profile.sub.split('|');

    this.getAvatarName = () => `${profile.given_name?.charAt(0)} ${profile.family_name?.charAt(0)}`;

    this.getUserId = () => profile.userId;

    this.userId = profile.userId;

    this.expired = () => new Date().getTime() > user.expires_at * 1000;
  }

  private getClaimValueAsArray(claimKey: string): string[] {
    const value = this.user.profile[claimKey];
    if (value == null) {
      return [];
    }
    return Array.isArray(value) ? value : [value];
  }

  hasAnyPermission(permissions: string[]) {
    return permissions.some((x) => this.permissions.includes(x));
  }

  hasEveryPermission(permissions: string[]) {
    return permissions.every((x) => this.permissions.includes(x));
  }

  hasPermission(permissions: string[], requireAll: boolean) {
    return requireAll ? this.hasEveryPermission(permissions) : this.hasAnyPermission(permissions);
  }

  canEditTask(creator: string, assignees: ITaskAsignee[]) {
    // TODO: May have to change if we start using Teams again
    const userId = this.getUserId();
    // If the user is allowed to edit tasks...
    if (this.hasEveryPermission(['Tasks.Edit'])) {
      // ... and the user is the task creator or an assignee
      if (userId === creator || assignees.some((a: ITaskAsignee) => a.id === userId)) {
        return true;
      }
    }
    return false;
  }
}
