import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@nivo/core';
import { BoxLegendSvg } from '@nivo/legends';
import { ResponsiveSunburst } from '@nivo/sunburst';
import { Chip } from '@nivo/tooltip';
import { useGetMySites, useGetMyTasks } from 'apollo-hooks';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { ModuleContext } from 'components/Modules/ModuleContext';
import { ProgressBar } from 'components/Shared/ProgressBar';
import { addYears, format, isBefore } from 'date-fns';
import React, { useContext } from 'react';
import { COLORS } from 'themes/colors';
import { IAppTheme } from 'themes/useGetTheme';
import { FilterOperation, PaginationOrder, TaskFilterBy, TaskOrderBy } from 'tillr-graphql';
import { partitionArray } from 'utils';

const getShade = (hexColor: string) => hexColor + 'bb';

const useStyles = makeStyles((theme: IAppTheme) => ({
  dashboardText: {
    '& h2': {
      fontSize: 22,
      margin: theme.spacing(0, 0, 2, 0),
      padding: theme.spacing(0, 0, 1, 0),
      borderBottom: 'solid 1px #ccc',
      color: '#111',
    },
    '& p': {
      margin: theme.spacing(0, 0, 2, 0),
      fontSize: 12,
    },
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(0.5, 1),
    alignItems: 'center',
  },
  tooltipText: {
    padding: theme.spacing(0, 0, 0, 1),
    fontWeight: 'bold',
  },
  label: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10em',
    fontWeight: 'bold',
    color: '#333333',
  },
}));

interface ITask {
  id: string;
  name: string;
  dueDate: string;
}

interface ITaskData {
  id: string;
  value: number | string | Date;
  children: ITaskData[];
  color?: string;
}

interface ICustomTooltipProps {
  color: string;
  id: string;
  value: number | string | Date;
  date?: string;
}

interface IProps {
  siteId: number;
}

export function TasksStatusForSite(props: IProps) {
  const { siteId } = props;

  const noTasksColor = '#efefef';
  const openTaskColor = COLORS.TASKS.OPEN;
  const overdueTaskColor = COLORS.TASKS.OVERDUE;
  const closedTaskColor = COLORS.TASKS.COMPLETED_ON_TIME;
  // Tasks completed late won't appear in this chart as we're only looking at upcoming tasks

  const classes = useStyles();
  const { module } = useContext(ModuleContext)!;

  const getOpenTasksQuery = useGetMyTasks({
    siteId,
    module,
    paginationProps: {
      pageSize: 9999,
      orderBy: TaskOrderBy.DueDate,
      order: PaginationOrder.Asc,
    },
    filterProps: {
      filters: [
        {
          filterBy: TaskFilterBy.Closed,
          argument: 'false',
          operation: FilterOperation.Equals,
        },
      ],
    },
  });

  const getClosedTasksQuery = useGetMyTasks({
    siteId,
    module,
    paginationProps: {
      pageSize: 9999,
      orderBy: TaskOrderBy.DueDate,
      order: PaginationOrder.Asc,
    },
    filterProps: {
      filters: [
        {
          filterBy: TaskFilterBy.Closed,
          argument: 'true',
          operation: FilterOperation.Equals,
        },
        {
          filterBy: TaskFilterBy.DueDate,
          argument: format(new Date(), 'dd MMM yyyy'),
          operation: FilterOperation.GreaterThan,
        },
        {
          filterBy: TaskFilterBy.DueDate,
          argument: format(addYears(new Date(), 2), 'dd MMM yyyy'),
          operation: FilterOperation.LessThan,
        },
      ],
    },
  });

  const getMySitesState = useGetMySites();

  const renderChart = (allOpenTasks: ITask[], closedTasks: ITask[]) => {
    const now = new Date();
    const [openTasks, overdueTasks] = partitionArray(allOpenTasks, (task) =>
      isBefore(now, new Date(task.dueDate)),
    );

    const tasksData: ITaskData = {
      id: `tasks ${Math.random()}`,
      value: 0,
      children: [
        {
          id: 'Open',
          value: 0,
          color: openTaskColor,
          children: openTasks.map((task) => ({
            id: `${task.name}:::${task.id}`,
            value: '1',
            color: getShade(openTaskColor),
            children: [],
          })),
        },
        {
          id: 'Overdue',
          value: 0,
          color: overdueTaskColor,
          children: overdueTasks.map((task) => ({
            id: `${task.name}:::${task.id}`,
            value: '1',
            color: getShade(overdueTaskColor),
            children: [],
          })),
        },
        {
          id: 'Completed',
          value: 0,
          color: closedTaskColor,
          children: closedTasks.map((task) => ({
            id: `${task.name}:::${task.id}`,
            value: '1',
            color: getShade(closedTaskColor),
            children: [],
          })),
        },
      ],
    };

    const totalTasks = allOpenTasks.length + closedTasks.length;

    if (totalTasks === 0) {
      tasksData.children[0].value = 1;
    }

    function CustomToolTip({ id, value, color }: ICustomTooltipProps): JSX.Element | null {
      const ttValue = id.indexOf(':::') !== -1 ? '' : value;
      if (totalTasks === 0) {
        return null;
      }
      return (
        <Box component="div" className={classes.tooltip}>
          <Chip color={color} />
          <span className={classes.tooltipText}>
            {id.split(':::')[0]}
            {` ${ttValue}`}
          </span>
        </Box>
      );
    }

    return (
      <ThemeProvider>
        <Box height={400} position="relative">
          <p className={classes.label}>{totalTasks}</p>
          <Box position="absolute">
            {/* // itemHeight + itemSpacing + default padding + some extra */}
            <svg height={tasksData.children?.length! * (14 + 4 + 2 + 10)}>
              <BoxLegendSvg
                symbolSize={12}
                anchor="top-left"
                itemWidth={120}
                itemHeight={14}
                itemsSpacing={4}
                // itemHeight + itemSpacing + default padding + some extra
                containerHeight={tasksData.children?.length! * (14 + 4 + 2 + 10)}
                containerWidth={240}
                direction="column"
                data={tasksData.children?.map((x) => ({ id: x.id!, label: x.id!, color: x.color }))}
              />
            </svg>
          </Box>
          <ResponsiveSunburst
            enableArcLabels={totalTasks > 0}
            // eslint-disable-next-line consistent-return
            arcLabel={(o: any) => {
              if (o.depth === 1) {
                return o.value;
              }
            }}
            arcLabelsSkipAngle={10}
            data={tasksData}
            margin={{
              top: 10,
              right: 0,
              bottom: 10,
              left: 0,
            }}
            tooltip={(tooltipProps: any) => (
              <CustomToolTip
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...tooltipProps}
              />
            )}
            cornerRadius={0}
            borderWidth={1}
            borderColor="white"
            colors={
              totalTasks > 0 ? [openTaskColor, overdueTaskColor, closedTaskColor] : [noTasksColor]
            }
            childColor={(parent: any, child: any) => child.data.color}
            animate
            motionConfig="gentle"
          />
        </Box>
      </ThemeProvider>
    );
  };

  const site = getMySitesState.data?.mySites?.find((x) => x.id === siteId);

  return (
    <div>
      <div className={classes.dashboardText}>
        <Typography variant="h2" display="block">
          {site?.name} Tasks
        </Typography>
        <Typography variant="body2" display="block">
          Upcoming tasks by status:
        </Typography>
      </div>
      {(getOpenTasksQuery.loading || getClosedTasksQuery.loading) && (
        <Box height={400} className={classes.loading}>
          <ProgressBar />
          <CircularProgress />
        </Box>
      )}
      {getOpenTasksQuery.error && <ErrorDisplay error={getOpenTasksQuery.error} />}
      {getClosedTasksQuery.error && <ErrorDisplay error={getClosedTasksQuery.error} />}
      {getOpenTasksQuery.data?.myTasks?.items &&
        getClosedTasksQuery.data?.myTasks?.items &&
        renderChart(getOpenTasksQuery.data.myTasks.items, getClosedTasksQuery.data.myTasks.items)}
    </div>
  );
}
