import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';
import { Alert } from '@material-ui/lab';
import {
  useGetMyNotificationPreferences,
  useGetMyTaskReminder,
  useSetMyTaskReminder,
} from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { NumberInput } from 'components/Shared/NumberInput';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NotificationType, SetMyTaskReminderMutationVariables, TaskType } from 'tillr-graphql';
import { UserProfileContext } from 'UserProfileContext';
import { formatItemCount } from 'utils';

type ReminderLevel = 'default' | 'override';

interface IProps {
  taskId: string;
  task: Pick<TaskType, 'open' | 'dueDate' | 'assignedToUsers'>;
}

export function TaskReminder(props: IProps) {
  const { taskId, task } = props;

  const userProfile = useContext(UserProfileContext)!;

  const getMyNotificationPreferencesState = useGetMyNotificationPreferences();
  const getMyTaskReminderState = useGetMyTaskReminder({ taskId });
  const [setMyTaskReminder, setMyTaskReminderState] = useSetMyTaskReminder({ taskId });

  const [reminderLevel, setReminderLevel] = useState<ReminderLevel>('default');
  const [overrideValue, setOverrideValue] = useState<number>(1);

  const [edit, setEdit] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (getMyTaskReminderState.data?.myTaskReminder) {
      setReminderLevel('override');
      setOverrideValue(getMyTaskReminderState.data.myTaskReminder.reminderPeriodInHours / 24);
    }
  }, [getMyTaskReminderState.data]);

  const handleEdit = () => {
    setEdit(true);
  };

  const handleCancel = () => {
    // Reset to initial value
    setReminderLevel(getMyTaskReminderState.data?.myTaskReminder ? 'override' : 'default');
    setEdit(false);
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  if (getMyNotificationPreferencesState.loading || getMyTaskReminderState.loading) {
    return <ActivityIndicator />;
  }

  if (getMyNotificationPreferencesState.error) {
    return <ErrorDisplay error={getMyNotificationPreferencesState.error} />;
  }

  if (getMyTaskReminderState.error) {
    return <ErrorDisplay error={getMyTaskReminderState.error} />;
  }

  // TODO: Check assignedToTeamsS
  const isAssignee = task.assignedToUsers.some((a) => a.id === userProfile.userId);
  const isOverdue = new Date(task.dueDate) < new Date();

  const taskReminderPreference =
    getMyNotificationPreferencesState.data?.myNotificationPreferences?.find(
      (x) => x.notificationType === NotificationType.TaskReminder,
    );

  const defaultDays = Number(taskReminderPreference?.customData ?? 0) / 24;
  const defaultDaysText = formatItemCount(defaultDays, 'day');

  const status = !isAssignee ? (
    'Cannot set reminder as you are not an assignee.'
  ) : !task.open ? (
    'Cannot set reminder as this task is already closed.'
  ) : isOverdue ? (
    'Cannot set reminder as this task is already overdue.'
  ) : getMyTaskReminderState.data?.myTaskReminder ? (
    <>
      You will be emailed a reminder <b>{formatItemCount(overrideValue, 'day')}</b> before this task
      is due.
    </>
  ) : taskReminderPreference?.isEnabled ? (
    <>
      You will be emailed a reminder <b>{defaultDaysText}</b> before this task is due.
    </>
  ) : (
    'Task reminders are disabled.'
  );

  const canSetReminder = isAssignee && task.open && !isOverdue;
  const isEnabled =
    canSetReminder &&
    (getMyTaskReminderState.data?.myTaskReminder || taskReminderPreference?.isEnabled);

  const handleChangePreference = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    setReminderLevel(value as 'default' | 'override');
  };

  const handleSubmit = () => {
    const variables: SetMyTaskReminderMutationVariables = {
      taskId,
      reminderPeriodInHours: reminderLevel === 'default' ? null : overrideValue * 24,
    };
    setMyTaskReminder({ variables }).then(() => {
      setShowSuccess(true);
      setEdit(false);
    });
  };

  return (
    <>
      <Tooltip title={status} placement="right">
        <Box>
          <IconButton
            aria-label="Edit task reminder"
            color="inherit"
            onClick={handleEdit}
            className="MuiPaper-elevation1"
            style={{ backgroundColor: 'white' }}
            disabled={!canSetReminder}
          >
            {isEnabled ? <NotificationsActiveOutlinedIcon /> : <NotificationsOffOutlinedIcon />}
          </IconButton>
        </Box>
      </Tooltip>

      <Snackbar
        open={showSuccess}
        autoHideDuration={5000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSuccess} severity="success" variant="filled">
          Task reminder updated!
        </Alert>
      </Snackbar>

      {edit && (
        <Dialog fullWidth maxWidth="xs" open onClose={handleCancel} aria-labelledby="dialog-title">
          <DialogTitle id="dialog-title">Edit task reminder</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup
                name="reminderLevel"
                value={reminderLevel}
                onChange={handleChangePreference}
              >
                <FormControlLabel
                  value="default"
                  control={<Radio />}
                  label={
                    <>
                      Use my default preference of{' '}
                      <Link to={`/notification-preferences?focus=${NotificationType.TaskReminder}`}>
                        {taskReminderPreference?.isEnabled ? defaultDaysText : 'disabled'}
                      </Link>
                      .
                    </>
                  }
                />
                <FormControlLabel
                  value="override"
                  control={<Radio />}
                  label={
                    <>
                      Remind me{' '}
                      <NumberInput
                        item="day"
                        value={overrideValue ?? 1}
                        onChange={setOverrideValue}
                        disabled={reminderLevel !== 'override'}
                      />{' '}
                      before this task is due.
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCancel}
              color="primary"
              disabled={setMyTaskReminderState.loading}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              disabled={setMyTaskReminderState.loading}
              endIcon={setMyTaskReminderState.loading ? <ActivityIndicator size="small" /> : null}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
