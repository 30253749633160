/* eslint-disable consistent-return */
import { Button, Paper, Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import { CancelButton } from 'components/Shared/CancelButton';
import { FormsButtons } from 'components/Shared/FormsButtons';
import { ProgressButton } from 'components/Shared/ProgressButton';
import { SiteContext } from 'components/Sites/SiteContext';
import { FormMode } from 'formly/FormMode';
import { getFieldTypeComponent } from 'formly/getFieldTypeComponent';
import { IFormlyConfig, IFormlyField } from 'formly/IFormlyConfig';
import { IModel } from 'formly/IModel';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

interface ITableRow {
  label: string;
  required: boolean;
  completed: any[];
  applicable: boolean;
  key: string;
}

interface IProps {
  config: IFormlyConfig;
  formId?: string;
  mode?: FormMode;
  model?: IModel;
  onSubmit?: (model: any) => void;
  onCancel?: () => void;
}

export function DocumentsList(props: IProps) {
  const {
    config,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formId,
    mode = 'create',
    model: initialModel = {},
    onSubmit,
    onCancel,
  } = props;
  const {
    handleSubmit,
    register,
    errors,
    setValue,
    clearErrors,
    setError,
    getValues,
    formState,
    reset,
    ...useFormProps
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [readOnly, setReadOnly] = useState<boolean>(true);

  function initialiseModel(model: any, formFields: IFormlyField[]) {
    if (mode !== 'create') return;
    formFields.forEach((x) => {
      if (x.key && x.defaultValue != null) {
        // eslint-disable-next-line no-param-reassign
        model[x.key] = x.defaultValue;
      }
      if (x.fieldGroup) {
        initialiseModel(model, x.fieldGroup);
      }
    });
  }

  initialiseModel(initialModel, config.fields);

  const [model, setModel] = useState<IModel>(initialModel);
  const { siteId } = useContext(SiteContext)!;

  const onValueUpdate = (fieldKey: string, value: any) => {
    setModel((prevState: any) => ({ ...prevState, [fieldKey]: value }));
  };

  function renderForm(): (JSX.Element | null)[] {
    return config.fields.map((el) => {
      const componentProps = {
        field: el,
        modelValue: model[el.key as string],
        onValueUpdate,
        readonly: readOnly,
        register,
        errors,
        setValue,
        clearErrors,
        setError,
        getValues,
        formState,
      };

      const fieldComponent = getFieldTypeComponent(el.type as string);

      return React.createElement(fieldComponent, {
        key: el.key as string,
        siteId,
        ...componentProps,
        ...useFormProps,
      });
    });
  }

  const handleCancel = () => {
    reset();
    if (onCancel) {
      onCancel();
    }
    setReadOnly(true);
  };

  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit(model);
      setReadOnly(true);
    }
  };

  const handleChangeMode = () => {
    setReadOnly(false);
  };

  return (
    <form noValidate onSubmit={handleSubmit(handleOnSubmit)} autoComplete="off">
      <TableContainer component={Paper}>
        <Table className="table--fixed" size="small">
          <TableHead>
            <TableRow>
              <TableCell className="table__key col--50">Document</TableCell>
              <TableCell className="col--25">Author</TableCell>
              <TableCell className="col--25">Created</TableCell>
              {/* {readOnly && (
              <TableCell>Actions</TableCell>
              )} */}
              {!readOnly && <TableCell>Applicable</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{renderForm()}</TableBody>
        </Table>
      </TableContainer>
      <FormsButtons>
        {!readOnly && (
          <>
            <CancelButton label="Cancel" onClick={handleCancel} />
            <ProgressButton label="Save" type="submit" />
          </>
        )}
        {readOnly && (
          <Tooltip title="Edit list to mark some documents as being not applicable">
            <Button
              className="no-wrap"
              color="secondary"
              variant="contained"
              startIcon={<EditIcon />}
              onClick={handleChangeMode}
            >
              Edit List
            </Button>
          </Tooltip>
        )}
      </FormsButtons>
    </form>
  );
}
